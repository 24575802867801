import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import {
  Table,
  Tooltip,
  DatePicker,
  Modal,
  Input,
  message,
  ConfigProvider,
  AutoComplete,
} from "antd";
import { getDaysArray } from "../../utils/common";
import Loader from "../../utils/loader";
import less from "./offlineCommission.module.scss";
import cx from "classnames";
import { getHoursAndMinFromMillis } from "../../utils/common";
import moment from "moment";

const { TextArea } = Input;
const TooltipStyle = {
  color: "white",
  inner: {
    fontFamily: "Poppins",
    color: "black",
  },
};

class OfflineCommission extends Component {
  state = {
    loading: true,
    tableLoader: false,
    globalModal: false,
    bulkUpdateModal: false,
    singleUpdateModal: false,
    isMobile: false,
    cityNames: [],
    tableData: [],
    selectedRowKeys: [],
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().add(7, "d").format("YYYY-MM-DD"),
  };

  async componentDidMount() {
    try {
      const stateObj = { loading: false };
      const services = await this.fetchServices();
      if (services && services.allCities) {
        stateObj.cityNames = services.allCities;
      }
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      stateObj.isMobile = regex.test(navigator.userAgent);
      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchServices = async () => {
    const url = `/commission/services`;

    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);
    if (!Array.isArray(response.services)) {
      message.error("Something went wrong");
    }
    if (response.services) {
      this.formatTable(response.services);
    }
    return response;
  };

  formatTable = (services) => {
    let { fromDate, toDate } = this.state;
    const columns = [
      {
        title: "Service Name",
        dataIndex: "name",
      },
      {
        title: "Start Time",
        dataIndex: "time",
        sorter: (a, b) => a.time.startTime - b.time.startTime,
        render: ({ startTime }) => (
          <>
            <span>{getHoursAndMinFromMillis(startTime)}</span>
          </>
        ),
      },
      {
        title: "Bus Type",
        dataIndex: "busAsset",
      },
      // {
      //   title: "Seat limit",
      //   dataIndex: "limit",
      //   sorter: (a, b) => a.limit.seatLimit - b.limit.seatLimit,
      //   render: ({ seatLimit, serviceId }) => (
      //     <div className={less.tableDiv}>
      //       <span>{seatLimit}</span>
      //       <img
      //         src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
      //         alt="edit"
      //         onClick={() =>
      //           this.handleDataChange(
      //             "Seatlimit",
      //             serviceId,
      //             undefined,
      //             undefined,
      //             seatLimit
      //           )
      //         }
      //       />
      //     </div>
      //   ),
      // },
    ];
    if (!toDate) {
      let tempFutureDate = new Date();
      tempFutureDate.setDate(tempFutureDate.getDate() + 7);
      toDate = moment(tempFutureDate).format("YYYY-MM-DD");
    }
    const daylist = getDaysArray(new Date(fromDate), new Date(toDate));
    const selectedDates = daylist.map((v) => v);
    for (let i = 0; i < selectedDates.length; i++) {
      let obj = selectedDates[i];
      let data = {
        title: obj,
        dataIndex: obj,
        render: ({ discount, serviceId, flatFare }) => (
          <div className={less.tableDiv}>
            <span>{discount}</span>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
              alt="edit"
              onClick={() =>
                this.handleDataChange(
                  "discount",
                  serviceId,
                  obj,
                  flatFare,
                  discount
                )
              }
            />
          </div>
        ),
      };
      columns.push(data);
    }

    const tableData = services.map((service) => {
      const sDates = selectedDates.map((obj) => {
        return {
          [obj]: {
            discount:
              service.tripDateWiseData && service.tripDateWiseData[obj]
                ? service.tripDateWiseData[obj].discount
                : undefined,
            flatFare:
              service.tripDateWiseData && service.tripDateWiseData[obj]
                ? service.tripDateWiseData[obj].flatFare
                : undefined,
            serviceId: service._id,
          },
        };
      });
      let dates = {};
      for (let i = 0; i < sDates.length; i++) {
        Object.assign(dates, sDates[i]);
      }
      return {
        ...dates,
        name: service.fromCityName + " to " + service.toCityName,
        time: { startTime: service.startTime },
        limit: { seatLimit: service.seatLimit, serviceId: service._id },
        key: service._id,
        busAsset: service.b2cAssetTypeName,
      };
    });

    this.setState({ columns, tableData });
  };

  fetchFilteredServices = async () => {
    this.setState({ tableLoader: true });
    const { fromCitySelected, toCitySelected } = this.state;
    let url = `/commission/services`;
    if (
      (fromCitySelected && !toCitySelected) ||
      (toCitySelected && !fromCitySelected)
    ) {
      message.error("Please select both from and to City");
    }
    if (fromCitySelected) {
      url += `?fromCitySelected=${fromCitySelected}`;
    }
    if (toCitySelected) {
      url += `&toCitySelected=${toCitySelected}`;
    }
    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);
    if (!Array.isArray(response.services)) {
      message.error("Not Found");
      this.setState({ tableLoader: false });
      return;
    }
    this.formatTable(response.services);
    this.setState({ tableLoader: false, selectedRowKeys: [] });
  };

  fetchGlobalCommission = async () => {
    let url = `/user/commission`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (!response.length) {
      message.error("User not found");
    }
    const globalCommission = response[0].globalCommission;
    this.setState({
      globalCommission,
      fetechedGlobalCommission: globalCommission,
    });
  };

  showModal = (toShow) => {
    if (toShow == "globalModal") {
      this.fetchGlobalCommission();
    }
    this.setState({ [toShow]: true });
  };

  handleChange = (key, value) => {
    if (key === "globalCommission") {
      const re = /^[0-9\b]+$/;
      if (value.length < 5 && (value === "" || re.test(value))) {
        this.setState({ globalCommission: value });
      }
    } else {
      this.setState({ [key]: value });
    }
  };

  handleDataChange = async (modalType, serviceId, date, flatFare, value) => {
    if (modalType == "Seatlimit") {
      this.setState({
        singleUpdateModal: true,
        tobeEdited: "Seat Limit",
        seatLimitUpdate: value,
        selectedServiceId: serviceId,
      });
    } else {
      //discount
      this.setState({
        singleUpdateModal: true,
        tobeEdited: "Percentage",
        percentageUpdate: value,
        selectedServiceId: serviceId,
        selectedDateData: { date, flatFare },
      });
    }
  };

  disableToDate = (current) => {
    const { fromDate } = this.state;
    let startRangeDate = current && current < moment(fromDate).endOf("day");
    let endRangeDate =
      current && current > moment(fromDate).endOf("day").add(14, "d");
    return !!startRangeDate || !!endRangeDate;
  };

  disableFromDate = (current) => {
    return current && current < moment().endOf("day").subtract(1, "d");
  };
  onFromDateChange = (date) => {
    let fromDate;
    if (date) {
      fromDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ fromDate });
  };

  onToDateChange = (date) => {
    let toDate;
    if (date) {
      toDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ toDate });
  };

  bulkUpdate = async () => {
    const {
      fromCitySelected,
      toCitySelected,
      selectedRowKeys,
      bulkSeatLimit,
      fromDate,
      toDate,
      bulkCommissionPercentage,
      tableData,
    } = this.state;
    if (
      bulkSeatLimit &&
      (parseInt(bulkSeatLimit) > 100 || parseInt(bulkSeatLimit) < 5)
    ) {
      message.error("Seat Limit Should Be In Between 5 to 100");
      return;
    }
    if (
      bulkCommissionPercentage &&
      (parseInt(bulkCommissionPercentage) > 100 ||
        parseInt(bulkCommissionPercentage) < 5)
    ) {
      message.error("Commission Should Be In Between 5 to 100");
      return;
    }
    const services = tableData.filter((service) => {
      return selectedRowKeys.some((id) => {
        return id === service.key;
      });
    });
    const daylist = getDaysArray(new Date(fromDate), new Date(toDate));
    const selectedDates = daylist.map((v) => v);
    const updateObj = services.map((data) => {
      let tripDateWiseData = {};
      if (bulkCommissionPercentage) {
        const sDates = selectedDates.map((obj) => {
          return {
            [obj]: {
              discount: parseInt(bulkCommissionPercentage),
              flatFare: data[obj] ? data[obj].flatFare : undefined,
            },
          };
        });

        for (let i = 0; i < sDates.length; i++) {
          Object.assign(tripDateWiseData, sDates[i]);
        }
      }
      return {
        _id: data.key,
        seatLimit: bulkSeatLimit ? parseInt(bulkSeatLimit) : undefined,
        tripDateWiseData:
          JSON.stringify(tripDateWiseData) != "{}"
            ? { ...tripDateWiseData }
            : undefined,
      };
    });
    const url = `/commission/services/update`;
    let obj = {
      updateObj,
      fromCitySelected,
      toCitySelected,
    };
    const options = {
      method: "post",
      data: obj,
    };

    const response = await Fetch(url, options);
    if (!response.length) {
      message.error("Something Went Wrong");
      return;
    } else {
      this.formatTable(response);
      this.setState({
        bulkUpdateModal: false,
        selectedRowKeys: [],
        bulkSeatLimit: undefined,
        bulkCommissionPercentage: undefined,
      });
    }
  };

  singleUpdate = async () => {
    const {
      fromCitySelected,
      toCitySelected,
      selectedServiceId,
      selectedDateData,
      percentageUpdate,
      seatLimitUpdate,
    } = this.state;

    if (
      seatLimitUpdate &&
      (parseInt(seatLimitUpdate) > 100 || parseInt(seatLimitUpdate) < 5)
    ) {
      message.error("Seat Limit Should Be In Between 5 to 100");
      return;
    }

    if (
      percentageUpdate &&
      (parseInt(percentageUpdate) > 100 || parseInt(percentageUpdate) < 5)
    ) {
      message.error("Commission Should Be In Between 5 to 100");
      return;
    }
    let updateObj = {
      _id: selectedServiceId,
    };
    if (seatLimitUpdate) {
      updateObj.seatLimit = parseInt(seatLimitUpdate);
    }
    if (percentageUpdate) {
      updateObj.tripDateWiseData = {
        [selectedDateData.date]: {
          discount: parseInt(percentageUpdate),
          flatFare: selectedDateData.flatFare,
        },
      };
    }

    const url = `/commission/services/update`;

    const obj = {
      updateObj: [updateObj],
      fromCitySelected,
      toCitySelected,
    };
    const options = {
      method: "post",
      data: obj,
    };

    const response = await Fetch(url, options);

    if (!response.length) {
      message.error("Something Went Wrong");
      return;
    } else {
      this.formatTable(response);
      this.setState({
        selectedDate: undefined,
        selectedServiceId: undefined,
        seatLimitUpdate: undefined,
        percentageUpdate: undefined,
        singleUpdateModal: false,
      });
    }
  };

  globalCommissionUpdate = async () => {
    const { globalCommission, fetechedGlobalCommission } = this.state;
    if (
      globalCommission &&
      (parseInt(globalCommission) > 100 || parseInt(globalCommission) < 5)
    ) {
      message.error("Commission Should Be In Between 5 to 100");

      return;
    }
    const url = `/user/commission/update`;

    const obj = {
      globalCommission,
      fetechedGlobalCommission,
    };
    const options = {
      method: "post",
      data: obj,
    };
    const response = await Fetch(url, options);

    if (response.length) {
      message.success("Global Commission Updated");
      this.setState({
        globalCommission: response[0].globalCommission,
        globalModal: false,
      });
    } else {
      message.success("Something went wrong while updating");
    }
  };

  render() {
    const {
      loading,
      isMobile,
      cityNames,
      columns,
      selectedRowKeys,
      tableData,
      tableLoader,
      globalModal,
      globalCommission,
      bulkUpdateModal,
      singleUpdateModal,
      bulkSeatLimit,
      bulkCommissionPercentage,
      fromDate,
      toDate,
      selectedDateData,
      seatLimitUpdate,
      percentageUpdate,
    } = this.state;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else {
      return (
        <div>
          <Navbar selectedKey={3} isMobile={isMobile} />
          <div class="container">
            <div className={cx("DealsHeader", less.amenitiesRow)}>
              <span className="dealSpan">Offline Commission </span>
              <div>
                <div>
                  <span onClick={() => this.showModal("globalModal")}>
                    Global Commission
                  </span>
                  <Tooltip
                    title="In cases where operators have not set specific commission rates for certain services, global commission acts as a fallback commission, It simplifies commission management for bus operators by automatically applying a commission rate in cases where they haven't specified one for a service. This reduces the need for constant manual intervention."
                    trigger="hover"
                    placement="bottom"
                    color={TooltipStyle.color}
                    overlayInnerStyle={TooltipStyle.inner}
                  >
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                      alt="info"
                    />
                  </Tooltip>
                </div>
                <div
                  className={
                    selectedRowKeys.length > 0
                      ? less.bulkDiv
                      : less.InActivebulkDiv
                  }
                >
                  <span
                    onClick={() =>
                      selectedRowKeys.length > 0
                        ? this.showModal("bulkUpdateModal")
                        : null
                    }
                  >
                    Bulk Update
                  </span>
                  <Tooltip
                    title="The bulk update button is your time-saving friend. It lets you change the commission for multiple bus services on various dates all at once. So, instead of editing them one by one, just select the services and dates you want to update, click the button, and it will work its magic. This way, you'll save loads of time and effort. It's like doing all your updates in a single snap!"
                    trigger="hover"
                    color={TooltipStyle.color}
                    overlayInnerStyle={TooltipStyle.inner}
                  >
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                      alt="info"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={less.searchHeader}>
              <div>
                <AutoComplete
                  placeholder="From City"
                  style={{
                    width: 200,
                  }}
                  options={cityNames}
                  onChange={(value) => {
                    let id = cityNames.filter(
                      (service) => service.value === value
                    );
                    if (id.length) {
                      this.setState({
                        fromCitySelected: id[0].value,
                      });
                    } else {
                      this.setState({
                        fromCitySelected: null,
                      });
                    }
                  }}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/change.svg"
                  alt="change"
                ></img>
                <AutoComplete
                  placeholder="To City"
                  style={{
                    width: 200,
                  }}
                  options={cityNames}
                  onChange={(value) => {
                    let id = cityNames.filter(
                      (service) => service.value === value
                    );
                    if (id.length) {
                      this.setState({
                        toCitySelected: id[0].value,
                      });
                    } else {
                      this.setState({
                        toCitySelected: null,
                      });
                    }
                  }}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
              <div>
                <span>From Date:</span>
                <DatePicker
                  format="DD-MM-YYYY"
                  disabledDate={this.disableFromDate}
                  onChange={this.onFromDateChange}
                />
                <span>To Date:</span>
                <DatePicker
                  disabledDate={this.disableToDate}
                  format="DD-MM-YYYY"
                  onChange={this.onToDateChange}
                />
              </div>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/search.svg"
                alt="search"
                onClick={this.fetchFilteredServices}
              />
            </div>
            {selectedRowKeys.length > 0 ? (
              <div className={less.serviceSelected}>
                <span> {selectedRowKeys.length} Service Selected</span>
                <img src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/selected.svg" />
              </div>
            ) : (
              <div className={less.noServiceSelected}>
                <span> No Services Selected</span>
              </div>
            )}

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7b2bff",
                },
              }}
            >
              <Table
                loading={tableLoader}
                columns={columns}
                bordered={true}
                scroll={{ x: "max-content" }}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (keys) => {
                    this.setState({
                      selectedRowKeys: keys,
                    });
                  },
                }}
                dataSource={tableData}
                pagination={false}
              />
            </ConfigProvider>
            <Modal
              centered
              title="Global Commission"
              open={globalModal}
              onCancel={() =>
                this.setState({
                  globalModal: false,
                })
              }
              footer={null}
            >
              <p className={less.modalSubheading}>
                This is the fallback commission percentage which will be updated
                on all the services
              </p>
              <div className={less.commisiondiv}>
                <span>Commission Percenatge</span>
                <Tooltip
                  title="Commission Should Be In Between 5 to 100"
                  trigger="hover"
                  color={TooltipStyle.color}
                  overlayInnerStyle={TooltipStyle.inner}
                >
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                    alt="info"
                  />
                </Tooltip>
              </div>
              <TextArea
                autoSize
                value={globalCommission}
                allowClear
                onChange={(e) =>
                  this.handleChange("globalCommission", e.target.value)
                }
                placeholder="Enter Percentage"
                className={less.TextArea}
              ></TextArea>
              <div className={less.divButton}>
                <button
                  onClick={() =>
                    this.setState({
                      globalModal: false,
                    })
                  }
                >
                  Close
                </button>
                <button onClick={this.globalCommissionUpdate}>
                  Save Updates
                </button>
              </div>
            </Modal>
            <Modal
              centered
              title="Update Parameters"
              open={bulkUpdateModal}
              onCancel={() =>
                this.setState({
                  bulkUpdateModal: false,
                })
              }
              footer={null}
            >
              <p className={less.modalSubheading}>
                For the selected trips and dates, enter the updated values. If
                the field is left blank, then the values will not be updated for
                the trips
              </p>
              <div className={less.commisiondiv}>
                <span>Offline Commission Percenatge</span>
                <Tooltip
                  title="Ranges between 5-100"
                  trigger="hover"
                  placement="right"
                  color={TooltipStyle.color}
                  overlayInnerStyle={TooltipStyle.inner}
                >
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                    alt="info"
                  />
                </Tooltip>
              </div>
              <TextArea
                autoSize
                value={bulkCommissionPercentage}
                onChange={(e) =>
                  this.handleChange("bulkCommissionPercentage", e.target.value)
                }
                placeholder="Enter Percentage"
                className={less.TextArea}
              ></TextArea>

              <div className={less.modalServiceInfo}>
                <span>Selected Service Count:</span>
                <span>{selectedRowKeys.length} services</span>
              </div>
              <div className={less.modalDateInfo}>
                <span>Date Range : </span>
                <span>
                  {moment(fromDate).format("DD/MM/YYYY")} to{" "}
                  {moment(toDate).format("DD/MM/YYYY")}
                </span>
              </div>

              <div className={less.divButton}>
                <button
                  onClick={() =>
                    this.setState({
                      bulkUpdateModal: false,
                    })
                  }
                >
                  Close
                </button>
                <button onClick={this.bulkUpdate}>Save Updates</button>
              </div>
            </Modal>
            <Modal
              centered
              title="Update Parameters"
              open={singleUpdateModal}
              onCancel={() =>
                this.setState({
                  selectedDateData: undefined,
                  selectedServiceId: undefined,
                  seatLimitUpdate: undefined,
                  percentageUpdate: undefined,
                  singleUpdateModal: false,
                })
              }
              footer={null}
            >
              {selectedDateData ? (
                <>
                  <div className={less.commisiondiv}>
                    <span>Commission Percentage Update</span>
                    <Tooltip
                      title="Ranges between 5-100"
                      trigger="hover"
                      placement="right"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>

                  <TextArea
                    allowClear
                    autoSize
                    value={percentageUpdate}
                    onChange={(e) =>
                      this.handleChange("percentageUpdate", e.target.value)
                    }
                    placeholder="Enter Percentage"
                    className={less.TextArea}
                  ></TextArea>
                  <div className={less.modalDateInfo}>
                    <span>Update percentage for </span>
                    <span>{selectedDateData.date}</span>
                  </div>
                </>
              ) : null}

              <div className={less.divButton}>
                <button
                  onClick={() =>
                    this.setState({
                      singleUpdateModal: false,
                    })
                  }
                >
                  Close
                </button>
                <button onClick={this.singleUpdate}>Save Updates</button>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

export default OfflineCommission;
