import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import {
  Table,
  Select,
  DatePicker,
  Modal,
  message,
  ConfigProvider,
  Button,
  Image,
} from "antd";
import Loader from "../../utils/loader";
import less from "./inspection.module.scss";
import cx from "classnames";
import { getHoursAndMinFromMillis } from "../../utils/common";
import moment from "moment";
import { inspectionOptions, cloundFrontBaseUrl } from "../../config";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import InspectionDataTile from "../../components/inspectionDataTile/inspectionDataTile";
import InspectionDataMoreInfoTile from "../../components/InspectionDataMoreInfoTile";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "BUS RC",
    dataIndex: "rc",
  },
  {
    title: "Completion Time",
    dataIndex: "time",
    sorter: (a, b) => a.date.completionTime - b.date.completionTime,
    render: ({ completionTime }) => (
      <>
        <span>
          {completionTime ? getHoursAndMinFromMillis(completionTime) : null}
        </span>
      </>
    ),
  },
  {
    title: "Completion Date",
    dataIndex: "date",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.date.completionDate - b.date.completionDate,
    render: ({ completionDate }) => (
      <>
        <span>
          {completionDate ? moment(completionDate).format("DD-MMM-YY") : null}
        </span>
      </>
    ),
  },
  {
    title: "View Data",
    dataIndex: "view",
    render: ({ showMoreDetails, _id }) => {
      return (
        <div>
          <Button onClick={() => showMoreDetails({ _id })}>View</Button>
        </div>
      );
    },
  },
];
const moreDetailsColumns = [
  {
    title: "Serial",
    dataIndex: "serial",
  },
  {
    title: "Action Performed",
    dataIndex: "action",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
  {
    title: "Remarks",
    dataIndex: "remark",
  },
  {
    title: "Attachments",
    dataIndex: "attachment",
    render: ({ attachments, openAttachment }) => {
      if (attachments && attachments.length > 0) {
        return attachments.map((data, index) => (
          <div key={index} className={less.attachment}>
            <div
              onClick={() =>
                openAttachment(data.attachmentUrl, data.attachmentType)
              }
            >
              {data.attachmentType === "image" ? (
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/image.svg"
                  alt="image"
                />
              ) : (
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/video.svg"
                  alt="video"
                />
              )}
              <span>{data.attachmentLabel}</span>
            </div>
          </div>
        ));
      } else {
        return <b>No Attachment</b>;
      }
    },
  },
];

class inspectionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      isMobile: false,
      moreDetailsData: [],
      showMoreDetails: false,
      showAttachmentModal: false,
      busRcsOptions: [],
      inspectionTableData: [],
      inspectionSelected: "inspectionone",
      showSideMenu: false,
      fromDate: null,
      toDate: null,
    };
  }

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const stateObj = { loading: false, isMobile };
      const busRcsOptions = await this.fetchRc();
      const data = await this.fetchCampaigns({
        inspectionSelected: "inspectionone",
        busRcsOptions,
      });
      stateObj.busRcsOptions = busRcsOptions;
      stateObj.inspectionTableData = data.inspectionTableData;
      stateObj.inspectionData = data.response;
      stateObj.inspectionName = data.inspectionName;

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchRc = async () => {
    const url = `/user/rc`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let busRcsOptions = [];
      response.map((data) => {
        let selectOpt = {
          value: data.rc,
          label: data.rc,
        };
        busRcsOptions.push(selectOpt);
      });
      return busRcsOptions;
    } else {
      message.error("something went wrong");
      return [];
    }
  };

  fetchCampaigns = async (data, clearDate) => {
    const { rc, inspectionSelected, fromDate, toDate, busRcsOptions } =
      this.state;
    let url = `/user/fetchInspectionData?type=groupWork`;
    if (data.rc || rc) {
      let dataToBeAdded = data.rc ? data.rc : rc;
      url += `&rc=${dataToBeAdded}`;
    } else {
      let rcArray = data.busRcsOptions ? data.busRcsOptions : busRcsOptions;
      let rc = rcArray
        .map((obj) => {
          return obj.value;
        })
        .join(",");
      url += `&rc=${rc}`;
    }
    if (data.inspectionSelected || inspectionSelected) {
      let dataToBeAdded = data.inspectionSelected
        ? data.inspectionSelected
        : inspectionSelected;
      url += `&name=${dataToBeAdded}`;
    }
    if (!clearDate) {
      if (data.fromDate || fromDate) {
        let dataToBeAdded = data.fromDate ? data.fromDate : fromDate;
        url += `&fromDate=${dataToBeAdded}`;
      }
      if (data.toDate || toDate) {
        let dataToBeAdded = data.toDate ? data.toDate : toDate;
        url += `&toDate=${dataToBeAdded}`;
      }
    }
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let inspectionTableData;
      inspectionTableData = response.map((obj) => {
        return {
          rc: obj.busRc,
          time: { completionTime: obj.closedDate ? obj.closedDate : undefined },
          date: { completionDate: obj.closedDate ? obj.closedDate : undefined },
          view: {
            showMoreDetails: this.showMoreDetails,
            _id: obj._id,
          },
          key: obj._id,
        };
      });
      let inspectionName = inspectionOptions.filter(
        (obj) => obj.value == inspectionSelected
      )[0].label;

      if (data.inspectionSelected) {
        inspectionName = inspectionOptions.filter(
          (obj) => obj.value == data.inspectionSelected
        )[0].label;
      }

      inspectionTableData = inspectionTableData.sort(
        (a, b) => b.time.completionTime - a.time.completionTime
      );

      let returnObj = {
        inspectionTableData,
        response,
        inspectionName,
      };
      return returnObj;
    } else {
      message.error("No Data Found");
      return [];
    }
  };

  showMoreDetails = (selectedId) => {
    const { inspectionData } = this.state;
    const inspectionMoreData = inspectionData.filter(
      (obj) => obj._id == selectedId._id
    )[0];
    let count = 0;
    const moreDetailsData = inspectionMoreData.work.map((obj) => {
      count = count + 1;
      let data = {
        serial: count,
        action: obj.name,
        value: obj.value,
        remark: obj.remark,
        attachment: {
          attachments: obj.attachments,
          openAttachment: this.showAttachment,
        },
      };
      return data;
    });
    this.setState({ showMoreDetails: true, moreDetailsData });
  };

  showAttachment = (attachmentUrl, type) => {
    const url = cloundFrontBaseUrl + attachmentUrl;
    const attachmentData = {
      attachmentUrl: url,
      attachmentType: type,
    };
    this.setState({
      showAttachmentModal: true,
      attachmentData,
    });
  };

  onFromDateChange = (date) => {
    let fromDate;
    if (date) {
      fromDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ fromDate });
  };

  onToDateChange = (date) => {
    let toDate;
    if (date) {
      toDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ toDate });
  };

  disabled7DaysDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 14;
    }
    return false;
  };

  onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      const fromDate = moment(dateStrings[0], "DD/MM/YYYY").valueOf();
      const toDate = moment(dateStrings[1], "DD/MM/YYYY")
        .endOf("day")
        .valueOf();

      const obj = { fromDate, toDate };
      const data = await this.fetchCampaigns(obj);

      this.setState({
        fromDate,
        toDate,
        inspectionTableData: data.inspectionTableData,
        inspectionData: data.response,
        inspectionName: data.inspectionName,
      });
    } else {
      const obj = { fromDate: null, toDate: null };
      const data = await this.fetchCampaigns(obj, true);

      this.setState({
        fromDate: null,
        toDate: null,
        inspectionTableData: data.inspectionTableData,
        inspectionData: data.response,
        inspectionName: data.inspectionName,
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  directSearh = async (key, value) => {
    const fetchData = { [key]: value };
    const data = await this.fetchCampaigns(fetchData);
    this.setState({
      [key]: value,
      inspectionTableData: data.inspectionTableData,
      inspectionData: data.response,
      inspectionName: data.inspectionName,
    });
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  render() {
    const {
      loading,
      isMobile,
      rc,
      inspectionName,
      tableLoader,
      inspectionTableData,
      moreDetailsData,
      busRcsOptions,
      showMoreDetails,
      attachmentData,
      showAttachmentModal,
      showSideMenu,
    } = this.state;

    let active = 2;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return (
        <>
          {isMobile ? (
            <>
              <MobileMainHeader toggleSideMenu={this.toggleSideMenu} />

              {showSideMenu ? (
                <MenuSlideDrawer
                  toggleSideMenu={this.toggleSideMenu}
                  showSideMenu={showSideMenu}
                  isMobile={isMobile}
                >
                  <SideMenu
                    active={active}
                    toggleSideMenu={this.toggleSideMenu}
                    isMobile={isMobile}
                  />
                </MenuSlideDrawer>
              ) : null}
              <div style={{ paddingTop: "75px" }}>
                <div className={less.pageHeading}>
                  {/* <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/backarrow.svg"
                    alt="back arrow"
                    className={less.backImg}
                  /> */}
                  <span className={less.pageHeadingText}>Inspection Data</span>
                </div>
              </div>
              <div className="container">
                <div className={less.searchHeader}>
                  <div>
                    <div>
                      <RangePicker
                        onChange={this.onRangeChange}
                        disabledDate={this.disabled7DaysDate}
                        format="DD/MM/YYYY"
                        popupClassName="dateRangePicker"
                      />
                    </div>
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div>
                    {" "}
                    <Select
                      style={{ width: "49%", marginRight: "4px" }}
                      placeholder="Select a RC"
                      onChange={(value) => this.directSearh("rc", value)}
                      options={busRcsOptions}
                    />
                    <Select
                      style={{ width: "49%" }}
                      defaultValue="Inspection Data 1"
                      placeholder="Select an Inspection"
                      onChange={(value) =>
                        this.directSearh("inspectionSelected", value)
                      }
                      options={inspectionOptions}
                    />
                  </div>
                </div>
                <div className={less.inspectionDataContainer}>
                  {!showMoreDetails &&
                  inspectionTableData &&
                  inspectionTableData.length
                    ? inspectionTableData.map((data, index) => (
                        <InspectionDataTile
                          key={index}
                          id={data.view}
                          busRc={data.rc}
                          completionTime={moment(
                            data.time.completionTime
                          ).format("hh:mm A")}
                          completionDate={moment(
                            data.date.completionDate
                          ).format("DD-MMM-YYYY")}
                          onButtonClick={this.showMoreDetails}
                        />
                      ))
                    : showMoreDetails &&
                      moreDetailsData &&
                      moreDetailsData.length
                    ? moreDetailsData.map((data, index) => {
                        return data ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <InspectionDataMoreInfoTile
                              key={index}
                              serial={data.serial}
                              action={data.action}
                              value={data.value}
                              remark={data.remark}
                              attachments={
                                data.attachment && data.attachment.attachments
                                  ? data.attachment.attachments
                                  : []
                              }
                              showAttachment={this.showAttachment}
                            />
                          </div>
                        ) : null;
                      })
                    : null}
                </div>
              </div>
              {attachmentData ? (
                <Modal
                  open={showAttachmentModal}
                  footer={null}
                  onCancel={() => this.setState({ showAttachmentModal: false })}
                >
                  {attachmentData.attachmentType == "image" ? (
                    <Image src={attachmentData.attachmentUrl} />
                  ) : attachmentData.attachmentType == "video" ? (
                    <video
                      width="320"
                      height="240"
                      controls
                      autoplay
                      muted
                      playsinline
                    >
                      <source src={attachmentData.attachmentUrl}></source>
                    </video>
                  ) : (
                    <audio controls>
                      <source src={attachmentData.attachmentUrl}></source>
                    </audio>
                  )}
                </Modal>
              ) : null}
            </>
          ) : (
            <div>
              <Navbar selectedKey={2} isMobile={isMobile} />
              <div className="container">
                <div className={cx("DealsHeader", less.amenitiesRow)}>
                  <span className="dealSpan">Inspection Data</span>
                </div>
                <div className={less.searchHeader}>
                  <div>
                    {" "}
                    <Select
                      style={{ width: "50%" }}
                      placeholder="Select a RC"
                      onChange={(value) => this.directSearh("rc", value)}
                      options={busRcsOptions}
                    />
                    <Select
                      style={{ width: "50%" }}
                      defaultValue="Inspection Data 1"
                      placeholder="Select an Inspection"
                      onChange={(value) =>
                        this.directSearh("inspectionSelected", value)
                      }
                      options={inspectionOptions}
                    />
                  </div>
                  <div>
                    <div>
                      <RangePicker
                        onChange={this.onRangeChange}
                        disabledDate={this.disabled7DaysDate}
                        format="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <Table
                    loading={tableLoader}
                    columns={columns}
                    bordered={true}
                    scroll={{ x: "max-content", y: isMobile ? "70vh" : null }}
                    dataSource={inspectionTableData}
                    pagination={false}
                  />
                </ConfigProvider>
              </div>

              <Modal
                closeIcon={null}
                width={"80%"}
                open={showMoreDetails}
                footer={null}
              >
                <div className={less.moreDetailsModal}>
                  <div>
                    <p>{inspectionName}</p>
                    <p>{rc}</p>
                  </div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/close.svg"
                    alt="close"
                    onClick={() =>
                      this.setState({
                        showMoreDetails: false,
                        moreDetailsData: [],
                      })
                    }
                  />
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <Table
                    columns={moreDetailsColumns}
                    dataSource={moreDetailsData}
                    pagination={false}
                  ></Table>
                </ConfigProvider>
              </Modal>
              {attachmentData ? (
                <Modal
                  open={showAttachmentModal}
                  footer={null}
                  onCancel={() => this.setState({ showAttachmentModal: false })}
                >
                  {attachmentData.attachmentType == "image" ? (
                    <Image src={attachmentData.attachmentUrl} />
                  ) : attachmentData.attachmentType == "video" ? (
                    <video
                      width="320"
                      height="240"
                      controls
                      autoplay
                      muted
                      playsinline
                    >
                      <source src={attachmentData.attachmentUrl}></source>
                    </video>
                  ) : (
                    <audio controls>
                      <source src={attachmentData.attachmentUrl}></source>
                    </audio>
                  )}
                </Modal>
              ) : null}
            </div>
          )}
        </>
      );
    }
  }
}

export default inspectionData;
