import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import Loader from "../../utils/loader";
import styles from "./deal.scss";
import { getHoursAndMinFromMillis } from "../../utils/common";
import {
  Select,
  Tag,
  Table,
  Tooltip,
  DatePicker,
  message,
  Checkbox,
  ConfigProvider,
  Divider,
  Modal,
} from "antd";
import moment from "moment";
import jwt from "jsonwebtoken";

const columns = [
  {
    title: "Service Name",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Bus Type",
    dataIndex: "busAsset",
    width: "30%",
  },
  {
    title: "Start Time",
    dataIndex: "time",
    width: "30%",
  },
];

const TooltipStyle = {
  color: "white",
  inner: {
    fontFamily: "Poppins",
    color: "black",
  },
};
class Deal extends Component {
  state = {
    loading: false,
    value: 1,
    offer: "flat",
    disAmount: undefined,
    stopDealTime: undefined,
    seatsOcc: undefined,
    occupancyAt: undefined,
    startDealTime: undefined,
    maxDiscount: undefined,
    percentageDis: undefined,
    step: 1,
    totalSteps: 4,
    selectedDates: [],
    stopOffer: undefined,
    dayIncluded: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
    seatAmount: undefined,
    selectedSevices: [],
    allServiceSelected: false,
    showSuccesModal: false,
    isMobile: false,
  };

  async componentDidMount() {
    try {
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      const name = decodedToken.name;
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      this.setState({ userName: name, isMobile });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      const { step } = this.state;
      if (step - 1 < 0) {
        this.props.history.goBack();
      } else {
        this.setState({ step: 1 });
      }
    };
  }

  fetchServiceNames = async () => {
    const url = `/user/services`;

    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);

    if (!Array.isArray(response)) {
      message.error("Something went wrong");
    }
    let services = response.map((obj) => {
      let returnObj = {
        name: obj.name,
        time: getHoursAndMinFromMillis(obj.startTime),
        _id: obj._id,
        assetType: obj.b2cAssetTypeName,
        fromCityName: obj.fromCityName,
        toCityName: obj.toCityName,
      };
      return returnObj;
    });

    services.sort(function (a, b) {
      if (a.fromCityName < b.fromCityName) {
        return -1;
      }
      if (a.fromCityName > b.fromCityName) {
        return 1;
      }
      return 0;
    });

    return services;
  };

  onOfferChange = (e) => {
    this.setState({ value: e.target.value });
  };

  onDiscountChange = (e) => {
    this.setState({ offer: e.target.value });
  };

  addDates = (date, dateString) => {
    const { selectedDates } = this.state;
    if (!selectedDates.includes(dateString)) {
      selectedDates.push(dateString);
      this.setState({ selectedDates });
    }
  };

  startDate = (e) => {
    this.setState({ startDate: e });
  };

  endDate = (e) => {
    this.setState({ endDate: e });
  };

  updateBackSteps = () => {
    const { step } = this.state;
    if (step == 1) {
      this.props.history.goBack();
    } else {
      this.setState({ step: step - 1 });
    }
  };

  onOK = () => {
    if (this.state.isMobile) {
      this.setState({
        showSuccesModal: false,
        value: 1,
        offer: "flat",
        disAmount: undefined,
        stopDealTime: undefined,
        seatsOcc: undefined,
        occupancyAt: undefined,
        startDealTime: undefined,
        maxDiscount: undefined,
        percentageDis: undefined,
        step: 1,
        totalSteps: 4,
        selectedDates: [],
        stopOffer: undefined,
        dayIncluded: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
        seatAmount: undefined,
        selectedSevices: [],
        allServiceSelected: false,
      });
    } else {
      this.props.history.replace("/");
    }
  };
  handleSelectedDates = (value) => {
    this.setState({ selectedDates: value });
  };

  tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"#7b2bff"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  handleDayChange = (value) => {
    this.setState({ dayIncluded: value });
  };

  getSelectedDates = () => {
    const { selectedDates } = this.state;
    let str = "";
    for (let i = 0; i < selectedDates.length; i++) {
      if (str.length == 0) {
        str += selectedDates[i];
      } else {
        let dateInString = " , " + selectedDates[i];
        str += dateInString;
      }
    }
    return <span>{str}</span>;
  };

  disabledStartDate = (current) => {
    return current && current < moment().endOf("day");
  };

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current && current < startDate;
  };

  disabledRangeDate = (current) => {
    const { startDate, endDate } = this.state;
    let startRangeDate = current && current < startDate;
    let endRangeDate = current && current > endDate;
    return !!startRangeDate || !!endRangeDate;
  };

  incrementSteps = async () => {
    let {
      step,
      disAmount,
      percentageDis,
      maxDiscount,
      seatAmount,
      startDate,
      endDate,
      dayIncluded,
      offer,
      occupancyAt,
      stopOffer,
      serviceTable,
      selectedRowKeys,
    } = this.state;
    let updateState = {};
    step = step + 1;
    if (
      offer == "flat" &&
      ((step == 2 && !disAmount) ||
        (disAmount &&
          (parseInt(disAmount) < 50 || parseInt(disAmount) >= 1001)))
    ) {
      message.error("Flat Discount amount Ranges from 50 to 1000");
      return;
    }
    if (
      offer !== "flat" &&
      ((step == 2 && !percentageDis) ||
        (percentageDis &&
          (parseInt(percentageDis) < 5 || parseInt(percentageDis) >= 101)))
    ) {
      message.error("Discount Percentage Ranges from “5 to “100");
      return;
    }
    if (
      offer !== "flat" &&
      ((step == 2 && !maxDiscount) ||
        (maxDiscount &&
          (parseInt(maxDiscount) < 50 || parseInt(maxDiscount) >= 1001)))
    ) {
      message.error("Maximum Discount Ranges from 50 to 1000");
      return;
    }
    if (
      (step == 2 && !seatAmount) ||
      (seatAmount &&
        (parseInt(seatAmount) < 50 || parseInt(seatAmount) >= 1001))
    ) {
      message.error("Minimum value of Seat Price Ranges from 50 to 1000");
      return;
    }
    if (!startDate && step == 3) {
      message.error("Start Date is Required");
      return;
    }
    if (!endDate && step == 3) {
      message.error("End Date is Required");
      return;
    }
    if (step == 3 && parseInt(occupancyAt) > 100) {
      message.error("occupancy cannot be greater than 100");
      return;
    }

    if (step == 3 && parseInt(stopOffer) > 100) {
      message.error("stopOffer cannot be greater than 100");
      return;
    }
    if (dayIncluded.length == 0 && step == 3) {
      message.error("Active Day is Required");
      return;
    }
    if (step == 3) {
      this.setState({ loading: true });
      let serviceNames = await this.fetchServiceNames();
      const serviceTable = serviceNames.map((service) => {
        return {
          name: service.fromCityName + " to " + service.toCityName,
          _id: service._id,
          time: service.time,
          busAsset: service.assetType,
          key: service._id,
        };
      });
      updateState.serviceTable = serviceTable;
      updateState.serviceNames = serviceNames;
      updateState.loading = false;
    }
    if (step == 4) {
      if (selectedRowKeys.length) {
        const filteredServiceTable = serviceTable.filter((serive) => {
          return selectedRowKeys.some((id) => {
            return id === serive.key;
          });
        });
        updateState.filteredServiceTable = filteredServiceTable;
      }
    }
    updateState.step = step;
    this.setState(updateState);
  };

  selectAllServices = () => {
    let { serviceNames, selectedSevices } = this.state;
    selectedSevices = [];
    serviceNames.map((obj) => {
      selectedSevices.push(obj);
    });
    this.setState({
      selectedSevices,
      allServiceSelected: true,
    });
  };

  handleServiceNameSelect = (value) => {
    let { selectedSevices } = this.state;
    selectedSevices = [];
    selectedSevices = value;
    this.setState({
      selectedSevices,
      allServiceSelected: false,
    });
  };

  publish = async () => {
    this.setState({ loading: true });
    const {
      selectedSevices,
      value,
      offer,
      startDate,
      endDate,
      dayIncluded,
      selectedDates,
      maxDiscount,
      seatsOcc,
      occupancyAt,
      disAmount,
      percentageDis,
      startDealTime,
      stopDealTime,
      stopOffer,
      seatAmount,
      userName,
    } = this.state;

    let excludedDates = [];
    if (selectedDates.length > 0) {
      excludedDates = selectedDates.map((obj) => {
        return moment(obj, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ss");
      });
    }

    let dealBody = {
      userName,
      selectedSevices,
      startDate,
      endDate,
      dayIncluded,
      excludedDates,
      minSeatValue: seatAmount ? parseInt(seatAmount) : undefined,
      type: value == 1 ? "CUSTOM" : value == 2 ? "EARLYBIRD" : "LASTMINUTE",
      discountType: offer == "flat" ? "ABSOLUTE" : "PERCENTAGE",
      discountValue:
        offer == "flat" ? parseInt(disAmount) : parseInt(percentageDis),
      status: "ACTIVE",
      maxDiscount: maxDiscount ? parseInt(maxDiscount) : undefined,
      startDealTime: startDealTime ? parseInt(startDealTime) : undefined,
      stopDealTime: stopDealTime ? parseInt(stopDealTime) : undefined,
      stopDealAtSeatCount: seatsOcc ? parseInt(seatsOcc) : undefined,
      stopDealAtOccupancy: stopOffer ? parseInt(stopOffer) : undefined,
      startDealAtOccupancy: occupancyAt ? parseInt(occupancyAt) : undefined,
    };

    const url = `/user/publishDeal`;

    const options = {
      method: "post",
      data: { dealBody },
    };

    const response = await Fetch(url, options);
    if (response.length > 0) {
      this.setState({
        loading: false,
        campaignDetailsId: response[0].name,
        showSuccesModal: true,
      });
    } else {
      message.error("Something went wrong");
    }
  };

  render() {
    const {
      value,
      step,
      disAmount,
      maxDiscount,
      percentageDis,
      seatAmount,
      selectedDates,
      startDate,
      endDate,
      totalSteps,
      offer,
      stopDealTime,
      seatsOcc,
      startDealTime,
      occupancyAt,
      stopOffer,
      dayIncluded,
      serviceNames,
      selectedSevices,
      loading,
      allServiceSelected,
      showSuccesModal,
      campaignDetailsId,
      isMobile,
      selectedRowKeys,
      serviceTable,
      filteredServiceTable,
    } = this.state;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else if (isMobile) {
      return (
        <div>
          <Navbar isMobile={isMobile} />
          <div>
            <div className="DealsHeader">
              <div className="headerDetails">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/back.svg"
                  alt="back"
                  onClick={() => {
                    this.updateBackSteps();
                  }}
                />
                <span className={styles.dealSpan}>
                  {step == 1
                    ? " Create a Deal"
                    : value == 1
                    ? " Custom Offer"
                    : value == 2
                    ? "  Early Bird"
                    : "  Last Min Deal"}{" "}
                </span>
                {step == 1 ? null : (
                  <img
                    src={
                      value == 1
                        ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                        : value == 2
                        ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                        : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                    }
                    alt="custom"
                  />
                )}
              </div>
              <div className="stepHeader">
                <p className="subHeading">
                  {" "}
                  Step {step} of {totalSteps}{" "}
                </p>
              </div>
            </div>
          </div>
          {step === 1 ? (
            <div className="step">
              <p className="heading">Choose Offer Type:</p>
              <div className="offers">
                <div>
                  <input
                    className="purple-Bg"
                    type="radio"
                    id="custom"
                    name="custom Offer"
                    value={1}
                    onChange={this.onOfferChange}
                    checked={value == 1 ? true : false}
                  />
                  <label className="subHeading" htmlFor="custom">
                    Custom Offer
                  </label>
                </div>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                  alt="custom"
                />
              </div>
              {/* <div className="offers">
                <div>
                  <input
                    className="purple-Bg"
                    type="radio"
                    id="earlyBird"
                    name="Early Bird Offer"
                    value={2}
                    onChange={this.onOfferChange}
                    checked={value == 2 ? true : false}
                  />
                  <label className="subHeading" htmlFor="earlyBird">
                    Early Bird Offer
                  </label>
                </div>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                  alt="bird"
                />
              </div>{" "} */}
              <div className="offers">
                <div>
                  <input
                    className="purple-Bg"
                    type="radio"
                    id="lastMin"
                    name="last Min Deal"
                    value={3}
                    onChange={this.onOfferChange}
                    checked={value == 3 ? true : false}
                  />
                  <label className="subHeading" htmlFor="lastMin">
                    Last Min Deal
                  </label>
                </div>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                  alt="hour"
                />
              </div>
              <p className="heading">Choose Discount Type:</p>
              <div className="offers">
                <div>
                  <input
                    className="purple-Bg"
                    type="radio"
                    id="flat"
                    name="flat discount"
                    value="flat"
                    onChange={this.onDiscountChange}
                    checked={offer == "flat" ? true : false}
                  />
                  <label className="subHeading" htmlFor="flat">
                    Flat Discount
                  </label>
                </div>
              </div>
              <div className="offers">
                <div>
                  <input
                    className="purple-Bg"
                    type="radio"
                    id="percentage"
                    name="Percentage"
                    value="percentage"
                    checked={offer == "flat" ? false : true}
                    onChange={this.onDiscountChange}
                  />
                  <label className="subHeading" htmlFor="percentage">
                    Percentage Discount
                  </label>
                </div>
              </div>
              <p className="heading">Enter Offer Details:</p>
              {offer == "flat" ? (
                <div>
                  <div className="alignCentre">
                    <span className="subHeading">Discount Amount</span>
                    <Tooltip
                      title="Flat Discount amount to be reduced on a booking. Ranges from “50” to “1000”"
                      trigger="click"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <div className="amountInput">
                    <span className="rupee">₹</span>
                    <div className="vl">
                      <input
                        type="text"
                        name="disAmount"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={disAmount}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ disAmount: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="alignCentre">
                    <span className="subHeading">Min. Seat Amount</span>
                    <Tooltip
                      title="Minimum value of Seat Price on which discount is applicable. Ranges from “50” to “1000”"
                      trigger="click"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <div className="amountInput">
                    <span className="rupee">₹</span>
                    <div className="vl">
                      <input
                        type="text"
                        name="seatAmount"
                        id="seatAmount"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={seatAmount}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ seatAmount: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="alignCentre">
                    <span className="subHeading">Discount Percentage</span>
                    <Tooltip
                      title="Discount Percentage applicable on a booking. Ranges from “5” to “100”"
                      trigger="click"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <div className="amountInput">
                    <div className="percentageInput">
                      <input
                        type="text"
                        name="percentageDis"
                        id="percentageDis"
                        inputmode="numeric"
                        className="endInput"
                        autoComplete="off"
                        pattern="\d*"
                        value={percentageDis}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ percentageDis: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <span className="percentage">%</span>
                  </div>
                  <div className="alignCentre">
                    <span className="subHeading">Max Discount</span>
                    <Tooltip
                      title="Maximum Discount applicable on a booking. Ranges from “50” to “1000”"
                      trigger="click"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <div className="amountInput">
                    <span className="rupee">₹</span>
                    <div className="vl">
                      <input
                        type="text"
                        name="maxDiscount"
                        id="maxDiscount"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={maxDiscount}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ maxDiscount: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="alignCentre">
                    <span className="subHeading">Min. Seat Amount</span>
                    <Tooltip
                      title="Minimum value of Seat Price on which discount is applicable. Ranges from “50” to “1000”"
                      trigger="click"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <div className="amountInput">
                    <span className="rupee">₹</span>
                    <div className="vl">
                      <input
                        type="text"
                        name="seatAmount"
                        id="seatAmount"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={seatAmount}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ seatAmount: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <button className="stepBtn" onClick={this.incrementSteps}>
                Next Step
              </button>
            </div>
          ) : step === 2 ? (
            <div className="step">
              <p className="heading borderBottom">Offer Validity</p>
              <div className="step2Dates">
                <div className="alignCentre">
                  <span className="subHeading">Start Date</span>
                  <Tooltip
                    title="Start date implies the travel date from which the deal is applicable."
                    trigger="click"
                    color={TooltipStyle.color}
                    overlayInnerStyle={TooltipStyle.inner}
                  >
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                      alt="info"
                    />
                  </Tooltip>
                </div>
                <DatePicker
                  value={startDate}
                  disabledDate={this.disabledStartDate}
                  inputReadOnly={true}
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  onChange={this.startDate}
                />
              </div>
              <div className="step2Dates">
                <div className="alignCentre">
                  <span className="subHeading">End Date</span>
                  <Tooltip
                    title="End date implies the travel date untill which the deal is applicable."
                    trigger="click"
                    color={TooltipStyle.color}
                    overlayInnerStyle={TooltipStyle.inner}
                  >
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                      alt="info"
                    />
                  </Tooltip>
                </div>
                <DatePicker
                  inputReadOnly={true}
                  disabledDate={this.disabledEndDate}
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  value={endDate}
                  onChange={this.endDate}
                />
              </div>
              <div className="step2Dates">
                <div className="alignCentre">
                  <span className="subHeading">Active days</span>
                  <Tooltip
                    title="Weekdays on which deal will be applicable."
                    trigger="click"
                    color={TooltipStyle.color}
                    overlayInnerStyle={TooltipStyle.inner}
                  >
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                      alt="info"
                    />
                  </Tooltip>
                </div>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  placeholder="Please select"
                  height={"20vh"}
                  onChange={this.handleDayChange}
                  tagRender={this.tagRender}
                  options={[
                    { value: "MON", label: "MON" },
                    { value: "TUE", label: "TUE" },
                    { value: "WED", label: "WED" },
                    { value: "THU", label: "THU" },
                    { value: "FRI", label: "FRI" },
                    { value: "SAT", label: "SAT" },
                    { value: "SUN", label: "SUN" },
                  ]}
                  value={dayIncluded.length > 0 ? dayIncluded : undefined}
                />
              </div>
              <div className="step2Dates">
                <div className="alignCentre">
                  <span className="subHeading">Exclusion Date</span>
                  <Tooltip
                    title="Exclusive Travel Dates on which the deals will not be applicable."
                    trigger="click"
                    color={TooltipStyle.color}
                    overlayInnerStyle={TooltipStyle.inner}
                  >
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                      alt="info"
                    />
                  </Tooltip>
                </div>

                <DatePicker
                  inputReadOnly={true}
                  disabledDate={this.disabledRangeDate}
                  style={{ width: "100%" }}
                  value={null}
                  format="DD-MM-YYYY"
                  onChange={this.addDates}
                />

                <Select
                  suffixIcon={null}
                  size="large"
                  hidden
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={this.handleSelectedDates}
                  // tagRender={this.tagRender}
                  placeholder="Selected Dates"
                  value={Object.values(selectedDates)}
                  notFoundContent={null}
                />
              </div>
              {value == 2 ? (
                <>
                  <p className="heading borderBottom">Deal Conditions</p>
                  <p className="subHeading"></p>
                  <div className="alignCentre">
                    <span className="subHeading">
                      Deactivate when ___ seats occupied
                    </span>
                  </div>
                  <div className="amountInput">
                    <div className="percentageInput">
                      <input
                        type="text"
                        className="endInput"
                        name="seatsOcc"
                        id="seatsOcc"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={seatsOcc}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ seatsOcc: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <span className="percentage">seats</span>
                  </div>
                  <div className="alignCentre">
                    <span className="subHeading">
                      or ___ days before departure
                    </span>
                  </div>
                  <div className="amountInput">
                    <div className="percentageInput">
                      <input
                        type="text"
                        className="endInput"
                        name="stopDealTime"
                        id="stopDealTime"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={stopDealTime}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ stopDealTime: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <span className="percentage">days</span>
                  </div>

                  <p className="disclaimer">
                    Note: Offers may get applied beyond defined ± 10% occupancy
                    in some cases
                  </p>
                </>
              ) : null}
              {value == 3 ? (
                <>
                  <p className="heading borderBottom">Deal Conditions</p>
                  <div className="alignCentre">
                    <span className="subHeading">
                      Offer starts ___ hours before the departure
                    </span>
                  </div>
                  <div className="amountInput">
                    <div className="percentageInput">
                      <input
                        type="text"
                        className="endInput"
                        name="startDealTime"
                        id="startDealTime"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={startDealTime}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ startDealTime: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <span className="percentage">hours</span>
                  </div>
                  <div className="alignCentre">
                    <span className="subHeading">
                      Offer is applicable when occupancy is more than ___
                    </span>
                  </div>

                  <div className="amountInput">
                    <div className="percentageInput">
                      <input
                        type="text"
                        className="endInput"
                        name="occupancyAt"
                        id="occupancyAt"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={occupancyAt}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ occupancyAt: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <span className="percentage">%</span>
                  </div>

                  <div className="alignCentre">
                    <span className="subHeading">
                      Stop Offer when occupancy is equal to ___
                    </span>
                  </div>
                  <div className="amountInput">
                    <div className="percentageInput">
                      <input
                        type="text"
                        className="endInput"
                        name="stopOffer"
                        id="stopOffer"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={stopOffer}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ stopOffer: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <span className="percentage">%</span>
                  </div>

                  <p className="disclaimer">
                    Note: Offers may get applied beyond defined ± 10% occupancy
                    in some cases
                  </p>
                </>
              ) : null}
              <button className="stepBtn" onClick={this.incrementSteps}>
                Next Step
              </button>
            </div>
          ) : step === 3 ? (
            <div className="step">
              <p className="heading borderBottom">Select All Services</p>
              <div className="selectService">
                <input
                  className="purple-Bg"
                  type="radio"
                  id="service"
                  name="Service Select"
                  value="service"
                  onChange={this.selectAllServices}
                  checked={allServiceSelected ? true : false}
                />
                <label className="subHeading labelService" htmlFor="service">
                  Select to apply offer on all services
                </label>
              </div>
              <div className="heading allServices">
                <span>Services</span>
                <span>Time</span>
              </div>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#7b2bff",
                  },
                }}
              >
                <Checkbox.Group
                  value={selectedSevices}
                  onChange={this.handleServiceNameSelect}
                >
                  {serviceNames.map((obj) => {
                    return (
                      <div className="checkBox">
                        <Checkbox
                          checked
                          value={obj}
                          indeterminate={allServiceSelected ? true : false}
                        >
                          {obj.fromCityName} to {obj.toCityName}
                        </Checkbox>
                        <div>{obj.time}</div>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </ConfigProvider>
              <div className="serviceButton">
                <button className="stepBtn" onClick={this.incrementSteps}>
                  Next Step
                </button>
              </div>
            </div>
          ) : step === 4 ? (
            <div className="step">
              <div>
                <p className="dealSpan">Final Campaign Details</p>
                <div className="finalData">
                  <span className="subHeading">Campaign Type : </span>
                  <span className="subHeading">
                    {value == 1
                      ? "Customer Offer"
                      : value == 2
                      ? "Early Bird"
                      : "Last Min Deal"}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Discount Type : </span>
                  <span className="subHeading">
                    {offer == "flat" ? "Flat" : "Percentage"}
                  </span>
                </div>

                <div className="finalData">
                  <span className="subHeading">
                    {offer == "flat"
                      ? "Discount Amount : ₹"
                      : "Discount Percentage : "}{" "}
                  </span>
                  <span className="subHeading">
                    {offer == "flat" ? disAmount : percentageDis + "%"}
                  </span>
                </div>
                {offer == "flat" ? (
                  <div className="finalData">
                    <span className="subHeading">Min. Offer per seat : ₹ </span>
                    <span className="subHeading">{seatAmount}</span>
                  </div>
                ) : (
                  <div className="minMaxDiscount">
                    <div className="finalData">
                      <span className="subHeading">
                        Min. Offer per seat : ₹{" "}
                      </span>
                      <span className="subHeading">{seatAmount}</span>
                    </div>

                    <div className="finalData">
                      <span className="subHeading">
                        Max. Offer per seat : ₹{" "}
                      </span>
                      <span className="subHeading">{maxDiscount}</span>
                    </div>
                  </div>
                )}
                <div className="finalData">
                  <span className="subHeading">Start Date : </span>
                  <span className="subHeading">
                    {moment(new Date(startDate).getTime()).format("LL")}{" "}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">End Date : </span>
                  <span className="subHeading">
                    {" "}
                    {moment(new Date(endDate).getTime()).format("LL")}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Active Days: </span>
                  <span className="subHeading">
                    {dayIncluded.includes("MON") ? (
                      <span className="days">M</span>
                    ) : null}
                    {dayIncluded.includes("TUE") ? (
                      <span className="days">T</span>
                    ) : null}
                    {dayIncluded.includes("WED") ? (
                      <span className="days">W</span>
                    ) : null}
                    {dayIncluded.includes("THU") ? (
                      <span className="days">T</span>
                    ) : null}
                    {dayIncluded.includes("FRI") ? (
                      <span className="days">F</span>
                    ) : null}
                    {dayIncluded.includes("SAT") ? (
                      <span className="days">S</span>
                    ) : null}
                    {dayIncluded.includes("SUN") ? (
                      <span className="days">S</span>
                    ) : null}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Exclusion Date: </span>
                  <span className="subHeading">
                    <this.getSelectedDates />
                  </span>
                </div>
              </div>
              {value == 2 ? (
                <p className="finalDisclaminer">
                  Deactivate when <b>{seatsOcc} seats</b> occupied or{" "}
                  <b>{stopDealTime} days</b> before departure
                </p>
              ) : value == 3 ? (
                <p className="finalDisclaminer">
                  Offer starts <b>{startDealTime} hrs </b>before departure if
                  more valid till occupancy becomes <b>{stopOffer}%</b>
                </p>
              ) : null}

              <div>
                <div className="dealSpan allServices">
                  <p>Sevices</p>
                </div>
                {selectedSevices.map((obj) => {
                  return (
                    <>
                      <div className="checkBox">
                        <p>
                          {obj.fromCityName} to {obj.toCityName}
                        </p>
                        <p>{obj.time}</p>
                      </div>
                      <ConfigProvider
                        theme={{
                          token: {
                            marginLG: 0,
                          },
                        }}
                      >
                        <Divider />
                      </ConfigProvider>
                    </>
                  );
                })}
              </div>
              <button onClick={this.publish} className="stepBtn">
                Publish Campaign
              </button>
            </div>
          ) : null}
          <Modal
            centered
            open={showSuccesModal}
            closable={false}
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={this.onOK}
            okText={"Done"}
            okButtonProps={{
              style: {
                background: "#7b2bff",
                width: "100%",
                height: "40px",
                marginTop: "10px",
                border: "none",
              },
            }}
          >
            <div className="modalDiv">
              <img
                src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operator/deals/success.webp"
                alt="success"
              />
              <p className="modalHeader">
                Your
                {value == 1
                  ? " Custom Deal "
                  : value == 2
                  ? "  Early Bird "
                  : "  Last Minute Deal "}
                has been activated
              </p>
              <p className="modalSubHeader">
                Campaign Code: {campaignDetailsId}
              </p>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          <Navbar isMobile={isMobile} />
          <div className="container">
            <div className="DealsHeader">
              <div className="headerDetails">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/back.svg"
                  alt="back"
                  onClick={() => {
                    this.updateBackSteps();
                  }}
                />
                <span className="dealSpan">
                  {step == 1
                    ? " Create a Deal"
                    : value == 1
                    ? " Custom Offer"
                    : value == 2
                    ? "  Early Bird"
                    : "  Last Min Deal"}{" "}
                </span>
                {step == 1 ? null : (
                  <img
                    src={
                      value == 1
                        ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                        : value == 2
                        ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                        : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                    }
                    alt="custom"
                  />
                )}
              </div>
              <div className="stepHeader">
                <p className="subHeading">
                  {" "}
                  Step {step} of {totalSteps}{" "}
                </p>
              </div>
            </div>
            {step === 1 ? (
              <>
                <div className="step">
                  <div className="left">
                    <div className="details">
                      <p className="heading">Choose Offer Type:</p>
                      <div className="offers">
                        <div>
                          <input
                            className="purple-Bg"
                            type="radio"
                            id="custom"
                            name="custom Offer"
                            value={1}
                            onChange={this.onOfferChange}
                            checked={value == 1 ? true : false}
                          />
                          <label className="subHeading" htmlFor="custom">
                            Custom Offer
                          </label>
                        </div>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                          alt="custom"
                        />
                      </div>
                      {/* <div className="offers">
                        <div>
                          <input
                            className="purple-Bg"
                            type="radio"
                            id="earlyBird"
                            name="Early Bird Offer"
                            value={2}
                            onChange={this.onOfferChange}
                            checked={value == 2 ? true : false}
                          />
                          <label className="subHeading" htmlFor="earlyBird">
                            Early Bird Offer
                          </label>
                        </div>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                          alt="bird"
                        />
                      </div>{" "} */}
                      <div className="offers">
                        <div>
                          <input
                            className="purple-Bg"
                            type="radio"
                            id="lastMin"
                            name="last Min Deal"
                            value={3}
                            onChange={this.onOfferChange}
                            checked={value == 3 ? true : false}
                          />
                          <label className="subHeading" htmlFor="lastMin">
                            Last Min Deal
                          </label>
                        </div>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                          alt="hour"
                        />
                      </div>
                      <div>
                        <p className="heading">Choose Discount Type:</p>
                        <div className="offers">
                          <div>
                            <input
                              className="purple-Bg"
                              type="radio"
                              id="flat"
                              name="flat discount"
                              value="flat"
                              onChange={this.onDiscountChange}
                              checked={offer == "flat" ? true : false}
                            />
                            <label className="subHeading" htmlFor="flat">
                              Flat Discount
                            </label>
                          </div>
                        </div>
                        <div className="offers">
                          <div>
                            <input
                              className="purple-Bg"
                              type="radio"
                              id="percentage"
                              name="Percentage"
                              value="percentage"
                              checked={offer == "flat" ? false : true}
                              onChange={this.onDiscountChange}
                            />
                            <label className="subHeading" htmlFor="percentage">
                              Percentage Discount
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="details">
                      <p className="heading">Enter Offer Details:</p>
                      {offer == "flat" ? (
                        <div>
                          <div className="alignCentre">
                            <span className="subHeading">Discount Amount</span>
                            <Tooltip
                              title="Flat Discount amount to be reduced on a booking. Ranges from “50” to “1000”"
                              color={TooltipStyle.color}
                              overlayInnerStyle={TooltipStyle.inner}
                            >
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                                alt="info"
                              />
                            </Tooltip>
                          </div>
                          <div className="textInput">
                            <span className="rupee">₹</span>
                            <div className="vl">
                              <input
                                type="text"
                                name="disAmount"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={disAmount}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      disAmount: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="alignCentre">
                            <span className="subHeading">Min. Seat Amount</span>
                            <Tooltip
                              title="Minimum value of Seat Price on which discount is applicable. Ranges from “50” to “1000”"
                              color={TooltipStyle.color}
                              overlayInnerStyle={TooltipStyle.inner}
                            >
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                                alt="info"
                              />
                            </Tooltip>
                          </div>
                          <div className="textInput">
                            <span className="rupee">₹</span>
                            <div className="vl">
                              <input
                                type="text"
                                name="seatAmount"
                                id="seatAmount"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={seatAmount}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      seatAmount: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="alignCentre">
                            <span className="subHeading">
                              Discount Percentage
                            </span>
                            <Tooltip
                              title="Discount Percentage applicable on a booking. Ranges from “5” to “100”"
                              color={TooltipStyle.color}
                              overlayInnerStyle={TooltipStyle.inner}
                            >
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                                alt="info"
                              />
                            </Tooltip>
                          </div>
                          <div className="textInput">
                            <div className="percentageInput">
                              <input
                                type="text"
                                name="percentageDis"
                                id="percentageDis"
                                inputmode="numeric"
                                className="endInput"
                                autoComplete="off"
                                pattern="\d*"
                                value={percentageDis}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      percentageDis: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <span className="percentage">%</span>
                          </div>
                          <div className="alignCentre">
                            <span className="subHeading">Max Discount</span>
                            <Tooltip
                              title="Maximum Discount applicable on a booking. Ranges from “50” to “1000”"
                              color={TooltipStyle.color}
                              overlayInnerStyle={TooltipStyle.inner}
                            >
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                                alt="info"
                              />
                            </Tooltip>
                          </div>
                          <div className="textInput">
                            <span className="rupee">₹</span>
                            <div className="vl">
                              <input
                                type="text"
                                name="maxDiscount"
                                id="maxDiscount"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={maxDiscount}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      maxDiscount: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="alignCentre">
                            <span className="subHeading">Min. Seat Amount</span>
                            <Tooltip
                              title="Minimum value of Seat Price on which discount is applicable. Ranges from “50” to “1000”"
                              color={TooltipStyle.color}
                              overlayInnerStyle={TooltipStyle.inner}
                            >
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                                alt="info"
                              />
                            </Tooltip>
                          </div>
                          <div className="textInput">
                            <span className="rupee">₹</span>
                            <div className="vl">
                              <input
                                type="text"
                                name="seatAmount"
                                id="seatAmount"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={seatAmount}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      seatAmount: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="buttonAlign">
                  <button className="stepBtn" onClick={this.incrementSteps}>
                    Next Step
                  </button>
                </div>
              </>
            ) : step === 2 ? (
              <>
                <div className="step">
                  <div className="left">
                    <div className="details">
                      <p className="heading">Offer Validity</p>
                      <div className="step2Dates">
                        <div className="alignCentre">
                          <span className="subHeading">Start Date</span>
                          <Tooltip
                            title="Start date implies the travel date from which the deal is applicable."
                            color={TooltipStyle.color}
                            overlayInnerStyle={TooltipStyle.inner}
                          >
                            <img
                              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                              alt="info"
                            />
                          </Tooltip>
                        </div>
                        <DatePicker
                          value={startDate}
                          disabledDate={this.disabledStartDate}
                          inputReadOnly={true}
                          style={{ width: "100%", height: "40px" }}
                          format="DD-MM-YYYY"
                          onChange={this.startDate}
                        />
                      </div>
                      <div className="step2Dates">
                        <div className="alignCentre">
                          <span className="subHeading">End Date</span>
                          <Tooltip
                            title="End date implies the travel date untill which the deal is applicable."
                            color={TooltipStyle.color}
                            overlayInnerStyle={TooltipStyle.inner}
                          >
                            <img
                              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                              alt="info"
                            />
                          </Tooltip>
                        </div>
                        <DatePicker
                          inputReadOnly={true}
                          disabledDate={this.disabledEndDate}
                          style={{ width: "100%", height: "40px" }}
                          format="DD-MM-YYYY"
                          value={endDate}
                          onChange={this.endDate}
                        />
                      </div>
                      <div className="step2Dates">
                        <div className="alignCentre">
                          <span className="subHeading">Active days</span>
                          <Tooltip
                            title="Weekdays on which deal will be applicable."
                            color={TooltipStyle.color}
                            overlayInnerStyle={TooltipStyle.inner}
                          >
                            <img
                              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                              alt="info"
                            />
                          </Tooltip>
                        </div>
                        <Select
                          mode="multiple"
                          style={{
                            width: "100%",
                          }}
                          size="large"
                          placeholder="Please select"
                          height={"20vh"}
                          onChange={this.handleDayChange}
                          tagRender={this.tagRender}
                          options={[
                            { value: "MON", label: "MON" },
                            { value: "TUE", label: "TUE" },
                            { value: "WED", label: "WED" },
                            { value: "THU", label: "THU" },
                            { value: "FRI", label: "FRI" },
                            { value: "SAT", label: "SAT" },
                            { value: "SUN", label: "SUN" },
                          ]}
                          value={
                            dayIncluded.length > 0 ? dayIncluded : undefined
                          }
                        />
                      </div>
                      <div className="step2Dates">
                        <div className="alignCentre">
                          <span className="subHeading">Exclusion Date</span>
                          <Tooltip
                            title="Exclusive Travel Dates on which the deals will not be applicable."
                            color={TooltipStyle.color}
                            overlayInnerStyle={TooltipStyle.inner}
                          >
                            <img
                              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                              alt="info"
                            />
                          </Tooltip>
                        </div>

                        <DatePicker
                          inputReadOnly={true}
                          disabledDate={this.disabledRangeDate}
                          style={{ width: "100%", height: "40px" }}
                          format="DD-MM-YYYY"
                          value={null}
                          onChange={this.addDates}
                        />
                        <Select
                          suffixIcon={null}
                          size="large"
                          hidden
                          mode="multiple"
                          style={{
                            width: "100%",
                          }}
                          onChange={this.handleSelectedDates}
                          // tagRender={this.tagRender}
                          placeholder="Selected Dates"
                          value={Object.values(selectedDates)}
                          notFoundContent={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="details">
                      {value == 2 ? (
                        <>
                          <p className="heading">Deal Conditions</p>
                          <p className="subHeading"></p>
                          <div className="alignCentre">
                            <span className="subHeading">
                              Deactivate when ___ seats occupied
                            </span>
                          </div>
                          <div className="textInput">
                            <div className="percentageInput">
                              <input
                                type="text"
                                className="endInput"
                                name="seatsOcc"
                                id="seatsOcc"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={seatsOcc}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({ seatsOcc: e.target.value });
                                  }
                                }}
                              />
                            </div>
                            <span className="percentage">seats</span>
                          </div>
                          <div className="alignCentre">
                            <span className="subHeading">
                              or ___ days before departure
                            </span>
                          </div>
                          <div className="textInput">
                            <div className="percentageInput">
                              <input
                                type="text"
                                className="endInput"
                                name="stopDealTime"
                                id="stopDealTime"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={stopDealTime}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      stopDealTime: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <span className="percentage">days</span>
                          </div>

                          <p className="disclaimer">
                            Note: Offers may get applied beyond defined ± 10%
                            occupancy in some cases
                          </p>
                        </>
                      ) : null}
                      {value == 3 ? (
                        <>
                          <p className="heading">Deal Conditions</p>
                          <div className="alignCentre">
                            <span className="subHeading">
                              Offer starts ___ hours before the departure
                            </span>
                          </div>
                          <div className="textInput">
                            <div className="percentageInput">
                              <input
                                type="text"
                                className="endInput"
                                name="startDealTime"
                                id="startDealTime"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={startDealTime}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      startDealTime: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <span className="percentage">hours</span>
                          </div>
                          <div className="alignCentre">
                            <span className="subHeading">
                              Offer is applicable when occupancy is more than
                              ___
                            </span>
                          </div>

                          <div className="textInput">
                            <div className="percentageInput">
                              <input
                                type="text"
                                className="endInput"
                                name="occupancyAt"
                                id="occupancyAt"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={occupancyAt}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      occupancyAt: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <span className="percentage">%</span>
                          </div>

                          <div className="alignCentre">
                            <span className="subHeading">
                              Stop Offer when occupancy is equal to ___
                            </span>
                          </div>
                          <div className="textInput">
                            <div className="percentageInput">
                              <input
                                type="text"
                                className="endInput"
                                name="stopOffer"
                                id="stopOffer"
                                inputmode="numeric"
                                autoComplete="off"
                                pattern="\d*"
                                value={stopOffer}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      stopOffer: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <span className="percentage">%</span>
                          </div>

                          <p className="disclaimer">
                            Note: Offers may get applied beyond defined ± 10%
                            occupancy in some cases
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="buttonAlign">
                  <button className="stepBtn" onClick={this.incrementSteps}>
                    Next Step
                  </button>
                </div>
              </>
            ) : step === 3 ? (
              <div className="step">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <Table
                    columns={columns}
                    bordered={true}
                    dataSource={serviceTable}
                    rowSelection={{
                      columnWidth: "10%",
                      selectedRowKeys,
                      onChange: (keys) =>
                        this.setState({
                          selectedRowKeys: keys,
                        }),
                    }}
                    pagination={false}
                  />
                </ConfigProvider>
                <div className="buttonAlign">
                  <button className="stepBtn" onClick={this.incrementSteps}>
                    Next Step
                  </button>
                </div>
              </div>
            ) : step === 4 ? (
              <div className="step">
                <p className="dealSpan">Final Campaign Details</p>

                <div className="left">
                  <div className="details">
                    <div className="finalData">
                      <span className="subHeading">Campaign Type : </span>
                      <span className="subHeading">
                        {value == 1
                          ? "Customer Offer"
                          : value == 2
                          ? "Early Bird"
                          : "Last Min Deal"}
                      </span>
                    </div>
                    <div className="finalData">
                      <span className="subHeading">Discount Type : </span>
                      <span className="subHeading">
                        {offer == "flat" ? "Flat" : "Percentage"}
                      </span>
                    </div>

                    <div className="finalData">
                      <span className="subHeading">
                        {offer == "flat"
                          ? "Discount Amount : ₹"
                          : "Discount Percentage :"}{" "}
                      </span>
                      <span className="subHeading">
                        {offer == "flat" ? disAmount : `${percentageDis}%`}
                      </span>
                    </div>
                    {offer == "flat" ? (
                      <div className="finalData">
                        <span className="subHeading">
                          Min. Offer per seat : ₹{" "}
                        </span>
                        <span className="subHeading">{seatAmount}</span>
                      </div>
                    ) : (
                      <div className="minMaxDiscount">
                        <div className="finalData">
                          <span className="subHeading">
                            Min. Offer per seat : ₹{" "}
                          </span>
                          <span className="subHeading">{seatAmount}</span>
                        </div>

                        <div className="finalData">
                          <span className="subHeading">
                            Max. Offer per seat : ₹{" "}
                          </span>
                          <span className="subHeading">{maxDiscount}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="right">
                    <div className="details">
                      <div className="finalData">
                        <span className="subHeading">Start Date : </span>
                        <span className="subHeading">
                          {moment(new Date(startDate).getTime()).format("LL")}{" "}
                        </span>
                      </div>
                      <div className="finalData">
                        <span className="subHeading">End Date : </span>
                        <span className="subHeading">
                          {" "}
                          {moment(new Date(endDate).getTime()).format("LL")}
                        </span>
                      </div>
                      <div className="finalData">
                        <span className="subHeading">Active Days: </span>
                        <span className="subHeading">
                          {dayIncluded.includes("MON") ? (
                            <span className="days">M</span>
                          ) : null}
                          {dayIncluded.includes("TUE") ? (
                            <span className="days">T</span>
                          ) : null}
                          {dayIncluded.includes("WED") ? (
                            <span className="days">W</span>
                          ) : null}
                          {dayIncluded.includes("THU") ? (
                            <span className="days">T</span>
                          ) : null}
                          {dayIncluded.includes("FRI") ? (
                            <span className="days">F</span>
                          ) : null}
                          {dayIncluded.includes("SAT") ? (
                            <span className="days">S</span>
                          ) : null}
                          {dayIncluded.includes("SUN") ? (
                            <span className="days">S</span>
                          ) : null}
                        </span>
                      </div>
                      <div className="finalData">
                        <span className="subHeading">Exclusion Date: </span>
                        <span className="subHeading">
                          <this.getSelectedDates />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                {value == 2 ? (
                  <p className="finalDisclaminer">
                    Deactivate when <b>{seatsOcc} seats</b> occupied or{" "}
                    <b>{stopDealTime} days</b> before departure
                  </p>
                ) : value == 3 ? (
                  <p className="finalDisclaminer">
                    Offer starts <b>{startDealTime} hrs </b>before departure if
                    occupancy is more than <b>{occupancyAt}%</b> and offer is
                    valid till occupancy becomes <b>{stopOffer}%</b>
                  </p>
                ) : null}

                <div className="serviceInfo">
                  <div className="dealSpan allServices">
                    <p>Sevices</p>
                  </div>
                  <Table
                    columns={columns}
                    bordered={true}
                    dataSource={filteredServiceTable}
                    pagination={false}
                  />
                </div>
                <div className="buttonAlign">
                  <button className="stepBtn" onClick={this.publish}>
                    Publish
                  </button>
                </div>
              </div>
            ) : null}
            <Modal
              centered
              open={showSuccesModal}
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
              onOk={this.onOK}
              okText={"Done"}
              okButtonProps={{
                style: {
                  padding: "0",
                  margin: "0",
                  width: "100%",
                  height: "44px",
                  background: "#7b2bff",
                  border: "none",
                },
              }}
            >
              <div className="modalDiv">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operator/deals/success.webp"
                  alt="success"
                />
                <p className="modalHeader">
                  Your
                  {value == 1
                    ? " Custom Deal "
                    : value == 2
                    ? "  Early Bird Deal "
                    : "  Last Minute Deal "}
                  has been activated
                </p>
                <p className="modalSubHeader">
                  Campaign Code: {campaignDetailsId}
                </p>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

export default Deal;
