import React, { Component } from "react";
import { Fetch, downloadPDF } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import { Table, DatePicker, message, ConfigProvider, Button } from "antd";
import Loader from "../../utils/loader";
import less from "./summary.module.scss";
import moment from "moment";
import cx from "classnames";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Operator Name",
    dataIndex: "operatorName",
    width: "12%",
  },
  {
    title: (
      <div>
        Supply <br />
        From Date
      </div>
    ),
    dataIndex: "supplyFrom",
    width: "8%",
  },
  {
    title: (
      <div>
        Supply <br />
        To Date
      </div>
    ),
    dataIndex: "supplyTo",
    width: "8%",
  },
  {
    title: "Commercials",
    dataIndex: "commercials",
    width: "8%",
  },
  {
    title: "TDS (%)",
    dataIndex: "tdsPercentage",
    width: "8%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "8%",
  },
  {
    title: "GST",
    dataIndex: "gst",
    width: "8%",
  },
  {
    title: "TDS",
    dataIndex: "tdsAmount",
    width: "8%",
  },
  {
    title: "Addition",
    dataIndex: "addition",
    width: "5%",
  },
  {
    title: (
      <div>
        Net Amount <br />
        to be released
      </div>
    ),
    dataIndex: "netAmount",
    width: "8%",
  },
  {
    title: "Download Summary",
    dataIndex: "download",
    render: ({ download, _id, weekStartDate, weekEndDate }) => {
      return (
        <div>
          <Button onClick={() => download(_id, weekStartDate, weekEndDate)}>
            Download
          </Button>
        </div>
      );
    },
    width: "8%",
  },
  {
    title: (
      <div>
        Summary <br />
        Generated On
      </div>
    ),
    dataIndex: "summary",
    width: "8%",
  },
];

class serviceContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      summaryTableData: [],
      modalData: {},
    };
  }

  async componentDidMount() {
    try {
      const stateObj = { loading: false };

      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 28);
      fromDate.setHours(0, 0, 0, 0);

      const toDate = new Date();
      toDate.setHours(23, 59, 59, 999);

      const fromDateMs = fromDate.getTime();
      const toDateMs = toDate.getTime();
      const dateObj = { fromDate: fromDateMs, toDate: toDateMs };

      const summary = await this.fetchSummary(dateObj);
      const formatTableData = this.formatData(summary);
      stateObj.summaryTableData = formatTableData;

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchSummary = async (data) => {
    let url = `/user/paymentSummary?`;
    if (data.fromDate) {
      let dataToBeAdded = data.fromDate;
      url += `&fromDate=${dataToBeAdded}`;
    }
    if (data.toDate) {
      let dataToBeAdded = data.toDate;
      url += `&toDate=${dataToBeAdded}`;
    }
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      return response;
    } else {
      message.error("No Summary Found");
      return [];
    }
  };

  formatData = (response) => {
    const data = response.map((data) => {
      return {
        operatorName: data.operatorName,
        supplyFrom: moment(data.weekStartDate).format("DD/MM/YY"),
        supplyTo: moment(data.weekEndDate).format("DD/MM/YY"),
        commercials: data.commercialPercentage,
        tdsPercentage: data.tdsPercentage,
        amount: data.amount,
        gst: data.gst,
        tdsAmount: data.tds,
        addition: data.addition,
        netAmount: data.netAmountToBeReleased,
        summary: moment(data.createdOn).format("DD/MM/YY"),
        download: {
          download: this.downloadPDF,
          _id: data._id,
          weekStartDate: data.weekStartDate,
          weekEndDate: data.weekEndDate,
        },
      };
    });
    return data;
  };

  onRangeChange = async (dates, dateStrings) => {
    let obj = { fromDate: null, toDate: null };
    if (dates) {
      const fromDate = moment(dateStrings[0], "DD/MM/YYYY").valueOf();
      const toDate = moment(dateStrings[1], "DD/MM/YYYY")
        .endOf("day")
        .valueOf();
      obj = { fromDate, toDate };
    }

    const summary = await this.fetchSummary(obj);
    const formatTableData = this.formatData(summary);
    let stateObj = {};
    stateObj = obj;
    stateObj.summaryTableData = formatTableData;

    this.setState(stateObj);
  };

  downloadPDF = async (selectedId, weekStartDate, weekEndDate) => {
    if (selectedId) {
      let downloadUrl = `/user/downloadSummary`;
      const options = {
        method: "post",
        data: { weekEndDate, weekStartDate },
      };

      const pdfString = await downloadPDF(downloadUrl, options);
      if (pdfString) {
        const blob = new Blob([pdfString], { type: "application/pdf" });

        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");

        a.href = url;

        a.download = "output.pdf";

        a.click();
      }
    }
  };

  render() {
    const { loading, tableLoader, summaryTableData, pdfString } = this.state;

    let active = 8;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return (
        <>
          <div>
            <Navbar selectedKey={active} isMobile={false} />
            <div className="container">
              <div className={cx("DealsHeader", less.amenitiesRow)}>
                <span className="dealSpan">Payment Summary</span>
              </div>
              <a href={pdfString} download>
                {" "}
              </a>
              <div className={less.searchHeader}>
                <div>
                  {" "}
                  <RangePicker
                    onChange={this.onRangeChange}
                    format="DD/MM/YYYY"
                  />
                </div>
              </div>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#7b2bff",
                  },
                }}
              >
                <Table
                  loading={tableLoader}
                  columns={columns}
                  bordered={true}
                  dataSource={summaryTableData}
                  pagination={false}
                  scroll={{ x: "200%" }}
                />
              </ConfigProvider>
            </div>
          </div>
        </>
      );
    }
  }
}

export default serviceContracts;
