import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import { Table, Select, message, ConfigProvider, Button, Modal } from "antd";
import Loader from "../../utils/loader";
import less from "./buses.module.scss";
import moment, { relativeTimeThreshold } from "moment";
import cx from "classnames";

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Frequency",
    dataIndex: "frequency",
  },
  {
    title: "Contract",
    dataIndex: "contract",
    render: ({ showMoreDetails, _id }) => {
      return (
        <div>
          <Button onClick={() => showMoreDetails(_id)}>View</Button>
        </div>
      );
    },
  },
  {
    title: "Bus Type",
    dataIndex: "type",
  },
];

class serviceContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      busRcsOptions: [],
      serviceNameOptions: [],
      sericeTableData: [],
      modalData: {},
    };
  }

  async componentDidMount() {
    try {
      const stateObj = { loading: false };
      const busRcsOptions = await this.fetchRc();
      const service = await this.fetchServices();
      const serviceOptions = this.servicesOption(service);
      const formatTableData = this.formatData(service);
      stateObj.service = service;
      stateObj.serviceNameOptions = serviceOptions;
      stateObj.busRcsOptions = busRcsOptions;
      stateObj.sericeTableData = formatTableData;

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchRc = async () => {
    const url = `/user/rc`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let busRcsOptions = [];
      response.map((data) => {
        let selectOpt = {
          value: data.rc,
          label: data.rc,
        };
        busRcsOptions.push(selectOpt);
      });
      return busRcsOptions;
    } else {
      message.error("No Rc Found");
      return [];
    }
  };

  fetchServices = async (data) => {
    const { rc, selectedService } = this.state;

    let url = `/user/service`;
    if (rc || (data && data.rc)) {
      let attachedRc = data && data.rc ? data.rc : rc;
      url += `?rc=${attachedRc}`;
    }
    if (selectedService || (data && data.selectedService)) {
      let attachedService =
        data && data.selectedService ? data.selectedService : selectedService;
      url += `?serviceId=${attachedService}`;
    }
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      return response;
    } else {
      message.error("No Service Found");
      return [];
    }
  };

  servicesOption = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      let serviceOptions = [];
      data.map((data) => {
        let selectOpt = {
          value: data._id,
          label: data.name,
        };
        serviceOptions.push(selectOpt);
      });
      return serviceOptions;
    } else {
      return [];
    }
  };

  formatData = (response) => {
    const data = response.map((data) => {
      return {
        serviceName: data.name,
        status: data.status,
        type: data.busType.name,
        contract: {
          showMoreDetails: this.showMoreDetails,
          _id: data._id,
        },
        frequency: data.busOperatorPayoutFrequency,
      };
    });
    return data;
  };

  directSearh = async (key, value) => {
    const fetchData = { [key]: value };
    const data = await this.fetchServices(fetchData);
    const formatTableData = this.formatData(data);
    this.setState({
      [key]: value,
      sericeTableData: formatTableData,
    });
  };

  showMoreDetails = (selectedId) => {
    const { service } = this.state;
    let stateObj = {};
    if (selectedId) {
      stateObj.showContractDetails = true;
      let selectedService = service.filter((obj) => obj._id === selectedId)[0];
      let selectedServiceContract = selectedService.contract;
      let serviceName = selectedService.name;
      const revenueBracket = selectedServiceContract.revenueBracket;
      const showTable = revenueBracket.length == 4;
      let modalObj = {
        showTable,
        serviceName,
        name: selectedServiceContract.name,
        runningCost: selectedServiceContract.runningCost,
        type: selectedServiceContract.type,
        status: selectedServiceContract.status,
        cancellationCharge: selectedServiceContract.cancellationCharge,
        freeDemandCancellation: selectedServiceContract.freeDemandCancellation,
        baseFuelRate: selectedServiceContract.baseFuelRate,
        roundTripToll: selectedServiceContract.roundTripToll,
        tableData: [
          {
            A: revenueBracket[0] ? revenueBracket[0].end : null,
            B: revenueBracket[1]
              ? revenueBracket[1].start +
                (revenueBracket[1].end ? " to " + revenueBracket[1].end : "")
              : null,
            C: revenueBracket[2]
              ? revenueBracket[2].start +
                (revenueBracket[2].end ? " to " + revenueBracket[2].end : "")
              : null,
            D: revenueBracket[3] ? "Above " + revenueBracket[3].start : null,
            sharing:
              revenueBracket[3] &&
              revenueBracket[3].zingbusShare &&
              revenueBracket[3].operatorShare
                ? revenueBracket[3].zingbusShare +
                  "-" +
                  revenueBracket[3].operatorShare
                : null,
          },
        ],
      };
      const modalTable = [
        {
          title: "A (Round Trip Fixed MG) ",
          dataIndex: "A",
        },
        {
          title: "B (Operator Share) ",
          dataIndex: "B",
        },
        {
          title: "C (zingbus share) ",
          dataIndex: "C",
        },
        {
          title: "D (Revenue sharing) ",
          dataIndex: "D",
        },
        {
          title: "Sharing Ratio (zingbus% - Operator %)",
          dataIndex: "sharing",
        },
      ];
      stateObj.modalData = modalObj;
      stateObj.modalTable = modalTable;
    } else {
      stateObj.modalData = {};
      stateObj.modalTable = [];
      stateObj.showContractDetails = false;
    }
    this.setState(stateObj);
  };
  render() {
    const {
      loading,
      rc,
      service,
      tableLoader,
      sericeTableData,
      busRcsOptions,
      serviceNameOptions,
      showContractDetails,
      modalData,
      modalTable,
      selectedService,
    } = this.state;

    let active = 7;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return (
        <>
          <div>
            <Navbar selectedKey={active} isMobile={false} />
            <div className="container">
              <div className={cx("DealsHeader", less.amenitiesRow)}>
                <span className="dealSpan">Services & Contracts</span>
              </div>
              <div className={less.searchHeader}>
                <div>
                  {" "}
                  {/* <Select
                    style={{ width: "50%" }}
                    placeholder="Select a RC"
                    value={rc}
                    onChange={(value) => this.directSearh("rc", value)}
                    options={busRcsOptions}
                  /> */}
                  <Select
                    style={{ width: "70%" }}
                    placeholder="Select a Service Name"
                    value={selectedService}
                    onChange={(value) =>
                      this.directSearh("selectedService", value)
                    }
                    options={serviceNameOptions}
                  />
                </div>
              </div>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#7b2bff",
                  },
                }}
              >
                <Table
                  loading={tableLoader}
                  columns={columns}
                  bordered={true}
                  //   scroll={{ x: "max-content", "70vh": null }}
                  dataSource={sericeTableData}
                  pagination={false}
                />
              </ConfigProvider>
            </div>

            <Modal
              open={showContractDetails}
              centered
              onCancel={() => this.showMoreDetails()}
              width={"100%"}
              footer={null}
            >
              <div className={less.modalDetails}>
                <div className={less.header}>
                  <span>Contract Details</span>
                  <span>{modalData.serviceName}</span>
                </div>
                <div>
                  <div className={less.contractDetails}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Contract Name</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.name}
                          </td>
                        </tr>

                        <tr>
                          <td>Round trip running kilometers</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.runningCost}
                          </td>
                        </tr>

                        <tr>
                          <td>Contract Type</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.type}
                          </td>
                        </tr>
                        <tr>
                          <td>Contract Status</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.status}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td>Cancellation Charge</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.cancellationCharge
                              ? "₹" + modalData.cancellationCharge
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Free Demand Cancellations</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.freeDemandCancellation}
                          </td>
                        </tr>
                        <tr>
                          <td>Base fuel rate</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.baseFuelRate
                              ? "₹" + modalData.baseFuelRate
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Round Trip Toll</td>
                          <td style={{ fontWeight: "bold" }}>
                            {modalData.roundTripToll
                              ? "₹" + modalData.roundTripToll
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {modalData.showTable ? (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <p>Revenue Rules:</p>
                  <Table
                    loading={tableLoader}
                    columns={modalTable}
                    bordered={true}
                    dataSource={
                      modalData && modalData.tableData
                        ? modalData.tableData
                        : []
                    }
                    pagination={false}
                  />
                </ConfigProvider>
              ) : null}
            </Modal>
          </div>
        </>
      );
    }
  }
}

export default serviceContracts;
