import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import Loader from "../../utils/loader";
import less from "./payouts.module.scss";
import cx from "classnames";
import { getHoursAndMinFromMillis } from "../../utils/common";
import moment from "moment";
import { DatePicker, ConfigProvider, Table, message, Button } from "antd";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "BO Name",
    dataIndex: "name",
  },
  {
    title: "Payout Amount",
    dataIndex: "amount",
  },
  {
    title: "Billing Start Date",
    dataIndex: "startDate",
  },
  {
    title: "Billing End Date",
    dataIndex: "endDate",
  },
  {
    title: "Inv. Generated On",
    dataIndex: "time",
  },
  {
    title: "Payment Advice",
    dataIndex: "action",
    render: ({ link }) => (
      <a href={link} target="_blank">
        Download
      </a>
    ),
  },
];

class seatFare extends Component {
  state = {
    loading: true,
    tableData: [],
  };

  async componentDidMount() {
    try {
      const stateObj = { loading: true };
      await this.fetchPayouts();
      stateObj.loading = false;
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      stateObj.isMobile = regex.test(navigator.userAgent);
      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  formatTable = (payout) => {
    let stateObj = {};
    let data = [];
    let headerData = {
      billingStartDate: moment(payout[0].billingStartDate).format("LL"),
      billingEndDate: moment(payout[0].billingEndDate).format("LL"),
      generatedOn: moment(payout[0].createdOn).format("LL"),
      amount: payout[0].paymentAmount,
    };
    stateObj.headerData = headerData;
    for (let i = 0; i < payout.length; i++) {
      let payoutData = payout[i];
      let tab = {
        name: payoutData.operatorUser.name,
        startDate: moment(payoutData.billingStartDate).format("LL"),
        endDate: moment(payoutData.billingEndDate).format("LL"),
        time: moment(payoutData.createdOn).format("LL"),
        amount: payoutData.paymentAmount,
        action: {
          link:
            "https://d1sk77eomznq49.cloudfront.net/" + payoutData.pnrWiseReport,
        },
      };
      data.push(tab);
    }
    stateObj.tableData = data;
    this.setState(stateObj);
  };

  fetchPayouts = async (fromDate, toDate) => {
    let url = `/user/payout`;
    if (fromDate && toDate) {
      url += `?fromDate=${fromDate}&toDate=${toDate}`;
    }
    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);
    if (!Array.isArray(response)) {
      message.error("No Data Found");
      return;
    }
    if (response) {
      this.formatTable(response);
    }
    return null;
  };

  onRangeChange = async (dates, dateStrings) => {
    let fromDate, toDate;
    if (dates) {
      fromDate = moment(dateStrings[0], "DD/MM/YYYY").valueOf();
      toDate = moment(dateStrings[1], "DD/MM/YYYY").valueOf();
      this.setState({ fromDate, toDate });
    } else {
      console.log("Clear");
    }
    this.fetchPayouts(fromDate, toDate);
  };

  render() {
    const { loading, isMobile, tableData, headerData } = this.state;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else {
      return (
        <div>
          <Navbar selectedKey={5} isMobile={isMobile} />
          <div class="container">
            <div className={less.Header}>
              <span className="dealSpan">Payout DashBoard</span>
              <div>
                <span>Select date range:</span>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <RangePicker
                    onChange={this.onRangeChange}
                    format="DD/MM/YYYY"
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className={less.info}>
              <span>Last Invoice Details</span>
              <div className={less.grid}>
                <div>
                  <span>Invoice Generated On:</span>
                  <span>{headerData ? headerData.generatedOn : null}</span>
                </div>
                <div>
                  <span>Billing start date :</span>
                  <span>{headerData ? headerData.billingStartDate : null}</span>
                </div>
                <div>
                  <span>Billing end date : </span>
                  <span>{headerData ? headerData.billingEndDate : null}</span>
                </div>
                <div>
                  <span>Payment Amount :</span>
                  <span>{headerData ? headerData.amount : null}</span>
                </div>
              </div>
            </div>
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Poppins",
                    fontSize: 12,
                  },
                  components: {
                    Table: {
                      headerBg: "#F5F5F5",
                    },
                  },
                }}
              >
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                ></Table>
              </ConfigProvider>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default seatFare;
