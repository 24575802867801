import React, { useState } from "react";
import { Button, Dropdown, message, ConfigProvider } from "antd";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import "./navBar.scss";

const Navbar = (props) => {
  const { isMobile, selectedKey } = props;
  const [sidebar, setSidebar] = useState(false);
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
    setSidebar(!sidebar);
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const deleteToken = () => {
    history.push("login");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
  };
  const token = localStorage.getItem("idToken");
  const decodedToken = jwt.decode(token);
  const items = [
    {
      label: "My Buses",
      key: "1",
    },
    {
      label: "Service Contracts",
      key: "2",
    },
    {
      label: "Payment Summary",
      key: "3",
    },
    {
      label: "Trips & Revenue",
      key: "4",
    },
  ];

  const onClick = ({ key }) => {
    if (key == 1) {
      routeChange("/myBuses");
    } else if (key == 2) {
      routeChange("/serviceContracts");
    } else if (key == 3) {
      routeChange("/paymentSummary");
    } else if (key == 4) {
      routeChange("/tripsRevenue");
    } else message.info(`Click on item ${key}`);
  };

  if (decodedToken.isMGUser) {
    return (
      <div className="desktopContainer">
        <div>
          <img
            className="Navlogo"
            src="https://d1flzashw70bti.cloudfront.net/original/images/operator/desktop/logo.svg"
            alt="logo"
          />
          <div
            onClick={() => {
              routeChange("/");
            }}
          >
            <div className="deals">
              {selectedKey == 1 ? (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/homeCol.svg"
                    alt="home"
                  />

                  <p className="dealNameColored">Home</p>
                </>
              ) : (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operato/navbar/home.svg"
                    alt="home"
                  />

                  <p className="dealName">Home</p>
                </>
              )}
            </div>
            <div className="grey"></div>
          </div>
          <div
            onClick={() => {
              routeChange("inspectionDataDetails");
            }}
          >
            <div className="deals">
              {selectedKey == 2 ? (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticketCol.svg"
                    alt="ticket"
                  />
                  <p className="dealNameColored">Inspection Data</p>
                </>
              ) : (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                    alt="deals"
                  />
                  <p className="dealName">Inspection Data</p>
                </>
              )}
            </div>
            <div className="grey"></div>
          </div>
          <div
            onClick={() => {
              routeChange("auditDataDetails");
            }}
          >
            <div className="deals">
              {selectedKey == 3 ? (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticketCol.svg"
                    alt="ticket"
                  />
                  <p className="dealNameColored">Audit Data</p>
                </>
              ) : (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                    alt="deals"
                  />
                  <p className="dealName">Audit Data</p>
                </>
              )}
            </div>
            <div className="grey"></div>
          </div>
          <div
            onClick={() => {
              routeChange("feedbackDetails");
            }}
          >
            <div className="deals">
              {selectedKey == 4 ? (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticketCol.svg"
                    alt="ticket"
                  />
                  <p className="dealNameColored">Feedback</p>
                </>
              ) : (
                <>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                    alt="deals"
                  />
                  <p className="dealName">Feedback</p>
                </>
              )}
            </div>
            <div className="grey"></div>
          </div>
          <div>
            <div className="deals">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#7b2bff",
                  },
                }}
              >
                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    onClick,
                    defaultSelectedKeys:
                      selectedKey == 6
                        ? [`1`]
                        : selectedKey == 7
                        ? [`2`]
                        : selectedKey == 8
                        ? [`3`]
                        : selectedKey == 9
                        ? [`4`]
                        : null,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    {selectedKey == 6 ||
                    selectedKey == 7 ||
                    selectedKey == 8 ||
                    selectedKey == 9 ? (
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/buscol.svg"
                        alt="bus"
                      />
                    ) : (
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/bus.svg "
                        alt="bus"
                      />
                    )}
                    {selectedKey == 6 ||
                    selectedKey == 7 ||
                    selectedKey == 8 ||
                    selectedKey == 9 ? (
                      <p className="dealNameColored">My Buses & Payments</p>
                    ) : (
                      <p className="dealName">My Buses & Payments</p>
                    )}
                  </a>
                </Dropdown>
              </ConfigProvider>
            </div>
          </div>
          <div className="bottomDetails">
            <div
              onClick={() => {
                routeChange("profile");
              }}
            >
              <div className="profileDetails">
                <div>
                  <p className="operatorName">Hi {decodedToken.name}</p>
                  <p>{decodedToken.mobileNo}</p>
                </div>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/tab/user.svg"
                  alt="profilePhoto"
                />
              </div>
            </div>
            <div className="grey"></div>

            <button className="confirmButton" onClick={deleteToken}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/logout.svg"
                alt="logout"
              ></img>
              <span>Logout Session</span>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    if (isMobile) {
      return (
        <div>
          <div className="navbar">
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/homePage/logo.svg"
              alt="zingbus"
            />
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashBoard/home/hamburger1.svg"
              alt="hamburger"
              onClick={showSidebar}
            />
          </div>
          <div className="grey"></div>
          <div
            onClick={showSidebar}
            className={sidebar ? "navbackground" : null}
          ></div>
          <div className={sidebar ? "nav-menu active" : "nav-menu"}>
            <div className="header">
              <span>Menu</span>
              <img
                src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operatorDashboard/navbar/close.webp"
                alt="close"
                onClick={showSidebar}
              />
            </div>
            <div
              onClick={() => {
                routeChange("profile");
              }}
            >
              <div className="profileDetails">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashBoard/home/profilePhoto.svg"
                  alt="profilePhoto"
                />
                <div>
                  <p className="operatorName">Hi {decodedToken.name}</p>
                  <p>{decodedToken.mobileNo}</p>
                </div>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operatorDashboard/navBar/arrow.webp"
                  alt="arrow"
                />
              </div>
            </div>
            <div
              onClick={() => {
                routeChange("operatorDealsDetails");
              }}
            >
              <div className="deals">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/navbar/deals.svg"
                  alt="deals"
                />
                <p className="dealName">Operator Deals</p>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operatorDashboard/navBar/arrow.webp"
                  alt="arrow"
                />
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("pricing");
              }}
            >
              <div className="deals">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/navBar/pricing.svg"
                  alt="deals"
                />
                <p className="dealName">Offline Pricing</p>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operatorDashboard/navBar/arrow.webp"
                  alt="arrow"
                />
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("offlineCommission");
              }}
            >
              <div className="deals">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/navBar/pricing.svg"
                  alt="deals"
                />
                <p className="dealName">Offline Commission</p>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operatorDashboard/navBar/arrow.webp"
                  alt="arrow"
                />
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("seatFare");
              }}
            >
              <div className="deals">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/navBar/pricing.svg"
                  alt="deals"
                />
                <p className="dealName">B2B Flat Fare</p>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operatorDashboard/navBar/arrow.webp"
                  alt="arrow"
                />
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("login");
              }}
            >
              <Button className="confirmButton" onClick={deleteToken}>
                Logout
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="desktopContainer">
          <div>
            <img
              className="Navlogo"
              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/desktop/logo.svg"
              alt="logo"
            />
            <div
              onClick={() => {
                routeChange("/");
              }}
            >
              <div className="deals">
                {selectedKey == 1 ? (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/homeCol.svg"
                      alt="home"
                    />

                    <p className="dealNameColored">Home</p>
                  </>
                ) : (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operato/navbar/home.svg"
                      alt="home"
                    />

                    <p className="dealName">Home</p>
                  </>
                )}
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("operatorDealsDetails");
              }}
            >
              <div className="deals">
                {selectedKey == 2 ? (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticketCol.svg"
                      alt="ticket"
                    />
                    <p className="dealNameColored">Operator Deals</p>
                  </>
                ) : (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                      alt="deals"
                    />
                    <p className="dealName">Operator Deals</p>
                  </>
                )}
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("offlineCommission");
              }}
            >
              <div className="deals">
                {selectedKey == 3 ? (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/offlineComissionCol.svg"
                      alt="commission"
                    />

                    <p className="dealNameColored">Offline Commission</p>
                  </>
                ) : (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/offlineComission.svg"
                      alt="commission"
                    />
                    <p className="dealName">Offline Commission</p>
                  </>
                )}
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("seatFare");
              }}
            >
              <div className="deals">
                {selectedKey == 4 ? (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/b2cFare.svg"
                      alt="fare"
                    />
                    <p className="dealNameColored">Offline Seat Fare</p>
                  </>
                ) : (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/fare.svg"
                      alt="fare"
                    />
                    <p className="dealName">Offline Seat Fare</p>
                  </>
                )}
              </div>
              <div className="grey"></div>
            </div>
            <div
              onClick={() => {
                routeChange("payouts");
              }}
            >
              <div className="deals">
                {selectedKey == 5 ? (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/payoutCol.svg"
                      alt="payout"
                    />
                    <p className="dealNameColored">Payouts</p>
                  </>
                ) : (
                  <>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/payout.svg"
                      alt="payout"
                    />
                    <p className="dealName">Payouts</p>
                  </>
                )}
              </div>
              <div className="grey"></div>
            </div>
            <div className="bottomDetails">
              <div
                onClick={() => {
                  routeChange("profile");
                }}
              >
                <div className="profileDetails">
                  <div>
                    <p className="operatorName">Hi {decodedToken.name}</p>
                    <p>{decodedToken.mobileNo}</p>
                  </div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/tab/user.svg"
                    alt="profilePhoto"
                  />
                </div>
              </div>
              <div className="grey"></div>

              <button className="confirmButton" onClick={deleteToken}>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/logout.svg"
                  alt="logout"
                ></img>
                <span>Logout Session</span>
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
};
export default Navbar;
