import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import { Table, Select, message, ConfigProvider, Button } from "antd";
import Loader from "../../utils/loader";
import less from "./buses.module.scss";
import moment from "moment";
import cx from "classnames";

const columns = [
  {
    title: "RC Number",
    dataIndex: "rc",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Bus Type",
    dataIndex: "type",
  },
];

class buses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      busRcsOptions: [],
      busTableData: [],
    };
  }

  async componentDidMount() {
    try {
      const stateObj = { loading: false };
      const rcs = await this.fetchRc();
      stateObj.busRcsOptions = rcs.busRcsOptions;
      stateObj.busTableData = rcs.formatTableData;
      stateObj.allData = rcs.formatTableData;
      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchRc = async () => {
    const url = `/user/rc`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let busRcsOptions = [];
      response.map((data) => {
        let selectOpt = {
          value: data.rc,
          label: data.rc,
        };
        busRcsOptions.push(selectOpt);
      });
      let formatTableData = this.formatData(response);
      return { busRcsOptions, response, formatTableData };
    } else {
      message.error("no Rc Found");
      return [];
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  formatData = (response) => {
    const data = response.map((data) => {
      return { rc: data.rc, status: data.status, type: data.busType.name };
    });
    return data;
  };

  directSearh = async (value) => {
    const { allData } = this.state;
    let filterData = allData.filter((data) => data.rc == value);
    this.setState({ busTableData: filterData });
  };

  render() {
    const { loading, rc, tableLoader, busTableData, busRcsOptions } =
      this.state;

    let active = 6;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return (
        <>
          <div>
            <Navbar selectedKey={active} isMobile={false} />
            <div className="container">
              <div className={cx("DealsHeader", less.amenitiesRow)}>
                <span className="dealSpan">Buses</span>
              </div>
              <div className={less.searchHeader}>
                <div>
                  {" "}
                  <Select
                    style={{ width: "50%" }}
                    placeholder="Select a RC"
                    value={rc}
                    onChange={(value) => this.directSearh(value)}
                    options={busRcsOptions}
                  />
                </div>
              </div>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#7b2bff",
                  },
                }}
              >
                <Table
                  loading={tableLoader}
                  columns={columns}
                  bordered={true}
                  //   scroll={{ x: "max-content", "70vh": null }}
                  dataSource={busTableData}
                  pagination={false}
                />
              </ConfigProvider>
            </div>
          </div>
        </>
      );
    }
  }
}

export default buses;
